import Vue from 'vue';

import { ERRORS_API_KEY, ERRORS_PROJECT_NAME } from '../constants/env';
import store from '../store';

const getStackTrace = (vm, stack = []) => {
  if (!vm.$options?.parent) return stack.filter((n) => !!n).join(' -> ');
  return getStackTrace(vm.$options.parent, [vm.$options.name, ...stack]);
};

const reportError = (message = '', stack, functionName) => {
  const { providerId, id, primaryRole, email } = store.state.user.info;
  let user;
  if (providerId && id && primaryRole && email) {
    user = `${providerId} / ${primaryRole} / ${id} / ${email}`;
  } else {
    user = 'Anonymous';
  }
  return window.gapi.client.clouderrorreporting.projects.events
    .report({
      projectName: `projects/${ERRORS_PROJECT_NAME}`,
      resource: JSON.stringify({
        serviceContext: {
          service: 'web',
          version: store.state.appVersion,
        },
        context: {
          httpRequest: {
            userAgent: window.navigator.userAgent,
            url: window.location.href,
          },
          reportLocation: {
            filePath: stack,
            functionName,
          },

          user,
        },
        message,
      }),
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.err('Error reporting error to stackdriver: ', err);
    });
};

const initErrors = () => {
  Vue.config.errorHandler = (err, vm, info) => {
    console.error(err);
    if (!err.stack) return;
    reportError(err.stack, getStackTrace(vm), info);
  };
};

export const reportComponentError = (err, vm, functionName) => {
  const { message } = { ...err };
  const searchQuery = new RegExp('user aborted a request');
  const wasAbortedManually = searchQuery.test(message);
  if (wasAbortedManually) return;
  reportError(err.stack, getStackTrace(vm), functionName);
};

const install = async () => {
  await new Promise((resolve) => {
    window.gapi.load('client', async () => {
      resolve();
    });
  });
  window.gapi.client.setApiKey(ERRORS_API_KEY);

  await new Promise((resolve) => {
    window.gapi.client.load(
      'https://clouderrorreporting.googleapis.com/$discovery/rest?version=v1beta1',
      'v1',
      resolve
    );
  });
  initErrors();
};

export default install;

export { reportError };
