// Lazyload imports
const loadView = (name) => {
  return () => import(`../views/${name}.vue`);
};

const routes = {
  component: loadView('Registerv1'),
  name: 'Registerv1',
  path: '/registerv1',
  meta: {
    public: true,
  },
  redirect: { name: 'RegisterGateway' },
  children: [
    {
      component: loadView('RegisterAssessment'),
      path: 'assessment',
      name: 'RegisterAssessment',
      meta: {
        disableTransition: true,
        hasMenu: true,
        hideSignIn: true,
        isChatEnabled: true,
        public: true,
      },
    },
    {
      component: loadView('RegisterComplete'),
      path: 'complete',
      name: 'RegisterComplete',
      meta: {
        footer: false,
        hasMenu: true,
        isChatEnabled: true,
        nextBtnText: 'Confirm',
        public: true,
      },
    },
    {
      component: loadView('RegisterConfirm'),
      path: 'confirm',
      name: 'RegisterConfirm',
      meta: {
        footer: true,
        hasMenu: true,
        isChatEnabled: true,
        nextBtnText: 'Confirm',
        public: true,
      },
    },
    {
      component: loadView('RegisterCovidLanding'),
      path: 'landing',
      name: 'RegisterCovidLanding',
      meta: {
        footer: false,
        hasMenu: true,
        isChatEnabled: true,
        public: true,
      },
    },
    {
      component: loadView('RegisterFileUpload'),
      path: 'upload',
      name: 'RegisterFileUpload',
      meta: {
        backDisabled: true,
        footer: true,
        hasMenu: true,
        isChatEnabled: true,
        public: true,
      },
    },
    {
      component: loadView('RegisterInform'),
      path: 'inform',
      name: 'RegisterInform',
      meta: {
        footer: true,
        hasMenu: true,
        isChatEnabled: true,
        public: true,
      },
    },
    {
      component: loadView('RegisterInformAbc'),
      path: 'informabc',
      name: 'RegisterInformAbc',
      meta: {
        footer: true,
        hasMenu: true,
        isChatEnabled: true,
        public: true,
      },
    },
    {
      component: loadView('RegisterInformCovid'),
      path: 'informCovid',
      name: 'RegisterInformCovid',
      meta: {
        footer: true,
        hasMenu: true,
        isChatEnabled: true,
        public: true,
      },
    },
    {
      component: loadView('RegisterIntroduce'),
      path: 'introduce',
      name: 'RegisterIntroduce',
      meta: {
        footer: true,
        hasMenu: true,
        isChatEnabled: true,
        public: true,
      },
    },

    {
      component: loadView('RegisterLogout'),
      path: 'logout',
      name: 'RegisterLogout',
      meta: {
        disableTransition: true,
        hasMenu: false,
        isChatEnabled: true,
        public: true,
      },
    },

    {
      component: loadView('RegisterNotFound'),
      path: 'page-not-found',
      name: 'RegisterNotFound',
      meta: {
        disableTransition: true,
        hasMenu: false,
        isChatEnabled: true,
        public: true,
      },
    },
    {
      component: loadView('RegisterSchedule'),
      path: 'schedule',
      name: 'RegisterSchedule',
      meta: {
        footer: true,
        hasMenu: true,
        isChatEnabled: true,
        public: true,
      },
    },
    {
      component: loadView('RegisterSignUp'),
      path: 'signup',
      name: 'RegisterSignUp',
      meta: {
        footer: true,
        hasMenu: true,
        isChatEnabled: true,
        nextBtnText: 'Confirm',
        public: true,
      },
    },
    {
      component: loadView('RegisterSurvey'),
      path: 'survey',
      name: 'RegisterSurvey',
      meta: {
        footer: true,
        hasMenu: true,
        hideSignIn: true,
        isChatEnabled: true,
        public: true,
      },
    },
    {
      component: loadView('RegisterThankyou'),
      path: 'thankyou',
      name: 'RegisterThankyou',
      meta: {
        disableTransition: false,
        hasMenu: true,
        hideSignIn: true,
        isChatEnabled: true,
        public: true,
      },
    },
    {
      component: loadView('RegisterTrt'),
      path: 'visit',
      name: 'RegisterTrt',
      meta: {
        disableTransition: true,
        hideSignIn: true,
        public: true,
      },
    },
    {
      component: loadView('RegisterVisit'),
      path: 'visit',
      name: 'RegisterVisit',
      meta: {
        disableTransition: true,
        hasMenu: true,
        isChatEnabled: true,
        public: true,
      },
    },
    // Register Gateway has to be last route resolved due to use of route param
    {
      component: loadView('RegisterGateway'),
      path: ':tenantUrl/:id?',
      name: 'RegisterGateway',
      meta: {
        public: true,
        hasMenu: true,
        isChatEnabled: false,
      },
    },
  ],
};

export default routes;
