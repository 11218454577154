/* eslint-disable no-nested-ternary */
export default {
  buttonStyles: {
    customButtonStyles: (vm) => {
      return {
        'border-radius': vm.card
          ? '8px'
          : !vm.hover && !vm.selected && !vm.disabled && !vm.card
          ? '3x'
          : '3px',
        background: vm.disabled
          ? 'rgba(0,0,0,0.3)'
          : !vm.selected && !vm.hover && !vm.active
          ? 'white!important'
          : '#f3ba44!important',

        color: '#3a3a3a!important',
        border: '1px solid #3b469933',

        transition: 'border-radius 0.3s, background 0.3s',
        'font-weight': '900!important',
        'font-family': "'Lato', sans-serif!important",
        'text-transform': 'uppercase!important',
      };
    },
  },
  theme: {
    anchor: '#1e88e5' /* blue -- info */,
    accent: {
      base: '#f3ba44' /* yellow */,
      darken1: '#979797' /* grey */,
    },
    info: {
      base: '#1e88e5' /* blue */,
      darken1: '#7460ee' /* indigo */,
      darken2: '#563dea' /* dark indigo */,
      darken3: '#6f42c1' /* purple */,
    },
    primary: {
      base: '#f3ba44' /* yellow */,

      lighten1: '#6c757d' /* lighter grey for text and menu hover */,
      lighten2: '#FFFFFF' /* white */,
      lighten3: '#faedd1' /** pale yellow (used in schedule) */,
      darken1: '#ffe4aa' /** darker yellow used as hover state on schedule */,
    },
    secondary: {
      base: '#3a3a3a' /* yellow */,
      darken1: '#009b74' /* slightly darker green */,
    },
    error: {
      base: '#fc4b6c' /* red */,
      lighten1: '#e83e8c' /* pink */,
    },
    success: {
      base: '#26c6da' /* sea green */,
      darken1: '#20c997' /* true green */,
    },
    warning: {
      base: '#ffb22b' /* yellow */,
      darken1: '#fd7e14' /* orange */,
      darken2: '#ef5350' /* dark orange */,
    },

    customGlobalStyles: {
      'font-family': "'Lato', sans-serif!important",
    },
    customStyleSheet: 'noble',
  },
};
