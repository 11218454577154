import { helpers } from 'vuelidate/lib/validators';

export const isValidJSON = (value) => {
  try {
    return !!JSON.parse(value);
  } catch (e) {
    return false;
  }
};

export const JSONize = (str) =>
  str
    // wrap keys without quote with valid double quote
    .replace(/([$\w]+)\s*:/g, (_, $1) => `"${$1}":`)
    // replacing single quote wrapped ones to double quote
    .replace(/'([^']+)'/g, (_, $1) => `"${$1}"`);

export const isValidJSONorObj = (str) => isValidJSON(str) || isValidJSON(JSONize(str));

// Returns an object with the key "__typename" removed from itself and its children objects
export const sanitizeGraphql = (obj) => {
  const copy = { ...obj };
  // eslint-disable-next-line  no-underscore-dangle
  delete copy.__typename;

  Object.keys(copy).forEach((key) => {
    if (copy[key] && typeof copy[key] === 'object' && !Array.isArray(copy[key])) {
      copy[key] = sanitizeGraphql(copy[key]);
    }
  });

  return copy;
};

export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch {
    return null;
  }
};

export const isValidToken = (token) => token && !['undefined', 'null'].includes(token);

export const areObjEqual = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

// helpers for Vuelidate Validation
export const healthCardDate = helpers.regex(
  // Doesn't check for leap years
  'healthCardDate',
  // eslint-disable-next-line no-useless-escape, max-len
  /^\d{4}[\-\/\s]?((((0[13578])|(1[02]))[\-\/\s]?(([0-2][0-9])|(3[01])))|(((0[469])|(11))[\-\/\s]?(([0-2][0-9])|(30)))|(02[\-\/\s]?[0-2][0-9]))$/
);

export const phone = helpers.regex('phone', /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/);
