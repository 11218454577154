export default {
  buttonStyles: {
    // eslint-disable-next-line no-unused-vars
    customButtonStyles: (vm) => {
      return {
        'border-radius': vm.card ? '8px' : '50px',
        background: !vm.disabled && !vm.selected && !vm.hover && '#f1efef',
        color: !vm.disabled && !vm.selected && !vm.hover && '#556f84',
        border: !vm.hover && !vm.selected && !vm.disabled && '1px solid #556f84',
      };
    },
    customButtonClasses: (vm) => {
      return {
        'secondary white--text font-weight-bold': vm.variant && !vm.disabled,
      };
    },
  },
  theme: {
    anchor: '#1e88e5' /* blue -- info */,
    accent: {
      base: '#1e88e5' /* blue -- info */,
      darken1: '#979797' /* grey */,
    },
    info: {
      base: '#1e88e5' /* blue */,
      darken1: '#7460ee' /* indigo */,
      darken2: '#563dea' /* dark indigo */,
      darken3: '#6f42c1' /* purple */,
    },
    primary: {
      base: '#d17874' /* salmon */,

      lighten1: '#6c757d' /* lighter grey for text and menu hover */,
      lighten2: '#FFFFFF' /* white */,
    },
    secondary: {
      base: '#556f84' /* steel gray-blue */,
      // base: '#6c757d' /* grey */,
      // lighten1: '#6c757d' /* lighter grey for text and menu hover */,
      lighten1: '#f5f5f5' /* light grey */,
      darken1: '#6c757d',
    },
    error: {
      base: '#fc4b6c' /* red */,
      lighten1: '#e83e8c' /* pink */,
    },
    success: {
      base: '#26c6da' /* sea green */,
      darken1: '#20c997' /* true green */,
    },
    warning: {
      base: '#ffb22b' /* yellow */,
      darken1: '#fd7e14' /* orange */,
      darken2: '#ef5350' /* dark orange */,
    },
    background: '#ffffff' /* light grey */,

    /**
     * The global custom styles field will apply the following styles globally
     * to an entire pageflow
     */

    customGlobalStyles: {
      color: '#707070',
      'font-family': "'Montserrat' sans-serif!important",
    },

    /**
     * If there is a stylesheet in /styles, it will be applied to this pageflow.
     */

    customStyleSheet: 'anova',
  },
};
