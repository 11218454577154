import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { en, fr } from 'vuetify/lib/locale';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  lang: {
    locales: { en, fr },
    current: 'en',
  },

  theme: {
    options: {
      customProperties: true,
    },
  },
});

export default vuetify;
