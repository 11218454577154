/* eslint-disable no-nested-ternary */
import anova from './pageflows/themes/anova';
import aurora from './pageflows/themes/aurora';
import ccc from './pageflows/themes/ccc';
import hybridpharm from './pageflows/themes/hybridpharm';
import mendo from './pageflows/themes/mendo';
import noble from './pageflows/themes/noble';
import pwa from './pageflows/themes/pwa';
import sensimed from './pageflows/themes/sensimed';
import vivo from './pageflows/themes/vivo';
import wholehealth from './pageflows/themes/wholehealth';
/**
 * Theme code corresponds to the "theme" argument supplied in a pageflow config
 * when calling the final createFlow() function
 */
export const themeCodes = {
  anova: 'ANOVA_THEME',
  aurora: 'AURORA_THEME',
  brown: 'WHOLE_HEALTH_THEME',
  ccc: 'CCC_THEME',
  hybridpharm: 'HYBRID_PHARM_THEME',
  mendo: 'MENDO_THEME',
  new: 'PORTAL_THEME_V2',
  noble: 'NOBLE_THEME',
  portal: 'PORTAL_THEME',
  pwa: 'PWA_THEME',
  sensimed: 'SENSIMED_THEME',
  vivo: 'VIVO_THEME',
};

/**
 * Themes for pageflow buttons
 */

export const buttonStyles = {
  PORTAL_THEME: 'inverted',
  PORTAL_THEME_V2: 'solid',
  ANOVA_THEME: anova.buttonStyles,
  AURORA_THEME: aurora.buttonStyles,
  CCC_THEME: ccc.buttonStyles,
  HYBRID_PHARM_THEME: hybridpharm.buttonStyles,
  MENDO_THEME: mendo.buttonStyles,
  NOBLE_THEME: noble.buttonStyles,
  PWA_THEME: pwa.buttonStyles,
  SENSIMED_THEME: sensimed.buttonStyles,
  VIVO_THEME: vivo.buttonStyles,
};

/**
 * Color pallettes for different pageflow themes, patient portral and other deprecated themes
 * keys correspond to Vuetify color pallete codes
 * https://v2.vuetifyjs.com/en/features/theme/#customizing
 *
 * See ANOVA_THEME for comments on special properties:
 * - customGlobalStyles
 * - customStyleSheet
 */
export const themes = {
  /**
   * general brand colors
   */
  BRAND_COLORS: {
    accent: '#38dfff',
    accent2: '#b7f5fb',
    cta: '#48d04c',
    deepBlue: '#050a30',
    emblems: '#2b8aff',
    lightBlue: '#34b6f8',
    lightTeal: '#9bd4e4',
  },

  /**
   * pageflow themes
   */
  ANOVA_THEME: anova.theme,
  AURORA_THEME: aurora.theme,
  CCC_THEME: ccc.theme,
  HYBRID_PHARM_THEME: hybridpharm.theme,
  MENDO_THEME: mendo.theme,
  NOBLE_THEME: noble.theme,
  PWA_THEME: pwa.theme,
  SENSIMED_THEME: sensimed.theme,
  VIVO_THEME: vivo.theme,
  WHOLE_HEALTH_THEME: wholehealth.theme,

  /**
   * portal themes
   */

  LIGHT_THEME: {
    primary: {
      base: '#039be5',

      lighten1: '#ffffff',
    },
    secondary: '#8BC34A',
    accent: '#9C27B0',
    error: '#F44336',
    warning: '#FF9800',
    info: {
      base: '#607D8B',
      lighten1: '#FFFFFF',
    },
    success: '#4CAF50',
  },
  OLD_PORTAL_THEME: {
    accent: {
      base: '#0F0A45' /* white */,
      darken1: '#979797' /* grey */,
    },
    info: {
      base: '#40ACA9' /* turquoise */,
      darken1: '#E3EAEF' /* grey-green */,
    },
    primary: {
      base: '#0F0A45' /* dark blue */,
      lighten1: '#F8F9FF' /* off-white */,
    },
    secondary: {
      base: '#E65B50' /* salmon */,
      lighten1: '#F48F71' /* light salmon */,
      lighten2: '#F4E5E0' /* pale salmon */,
    },
    error: '#FF7171' /* rose */,
    success: '#14A38B' /* blue green */,
    warning: '#F2AC57' /* orange-yellow */,
  },
  PORTAL_THEME: {
    anchor: '#1e88e5' /* blue -- info */,
    accent: {
      base: '#1e88e5' /* blue -- info */,
      darken1: '#979797' /* grey */,
    },
    info: {
      base: '#1e88e5' /* blue */,
      darken1: '#7460ee' /* indigo */,
      darken2: '#563dea' /* dark indigo */,
      darken3: '#6f42c1' /* purple */,
    },
    primary: {
      // base: '#6c757d' /* grey */,
      base: '#1d2228' /* dark grey for side menu */,
      // lighten1: '#f5f5f5' /* light grey */,
      lighten1: '#6c757d' /* lighter grey for text and menu hover */,
      lighten2: '#FFFFFF' /* white */,
    },
    secondary: {
      base: '#1d2228' /* dark grey for side menu */,
      // base: '#6c757d' /* grey */,
      // lighten1: '#6c757d' /* lighter grey for text and menu hover */,
      lighten1: '#f5f5f5' /* light grey */,
      darken1: '#6c757d',
    },
    error: {
      base: '#fc4b6c' /* red */,
      lighten1: '#e83e8c' /* pink */,
    },
    success: {
      base: '#26c6da' /* sea green */,
      darken1: '#20c997' /* true green */,
    },
    warning: {
      base: '#ffb22b' /* yellow */,
      darken1: '#fd7e14' /* orange */,
      darken2: '#ef5350' /* dark orange */,
    },
    background: '#f4f6f9' /* light grey */,
    loadingBar: '#2b8aff' /* sentry blue */,
  },
  PORTAL_THEME_V2: {
    anchor: '#1e88e5' /* blue -- info */,
    accent: {
      base: '#9fd5e6' /* light teal */,
    },
    info: {
      base: '#1e88e5' /* blue */,
      darken1: '#7460ee' /* indigo */,
      darken2: '#563dea' /* dark indigo */,
      darken3: '#6f42c1' /* purple */,
    },
    primary: {
      base: '#173981' /* blue */,
      lighten1: '#234FAD' /* lighter blue for hover */,
      darken1: '#050a30' /* dark navy blue */,
    },
    secondary: {
      base: '#1F6DA1' /* grayish blue */,
    },
    error: {
      base: '#fc4b6c' /* red */,
      lighten1: '#e83e8c' /* pink */,
    },
    success: {
      base: '#26c6da' /* sea green */,
      darken1: '#20c997' /* true green */,
    },
    warning: {
      base: '#ffb22b' /* yellow */,
      darken1: '#fd7e14' /* orange */,
      darken2: '#ef5350' /* dark orange */,
    },
    background: '#ffffff' /* white */,
  },

  REGISTRATION_THEME: {
    primary: {
      lighten1: '#F8F9FF',
    },
    secondary: '#0C0645',
    accent: '#E75B4F',
  },

  CALENDAR_SCHEME: {
    cancel: '#EF5350',
    default: '#546E7A',
  },
};
