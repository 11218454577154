export default {
  addressInput: {
    placeholder: 'Rechercher un lieu',
  },
  logo: {
    ccc: 'Cliniques de Cannabis Canadiennes',
    default: 'Sentry Health',
    mendo: 'Mendo Cannabis Médical',
  },
  main: {
    appointment: 'Rendez-vous',
    back: 'Retour',
    continue: 'Continuer',
    delete: 'Supprimer',
    dob: 'Date de naissance',
    email: 'Courriel',
    gender: {
      male: 'Mâle',
      female: 'Femelle',
    },
    firstName: 'Prénom',
    hcn: 'Numéro de carte santé',
    inPerson: 'En personne',
    lastName: 'Nom',
    location: 'Emplacement',
    mailingAddress: 'Adresse postale',
    patient: 'Patient',
    pin: 'NIP',
    phone: 'Tel',
    provider: 'Practicien',
    province: 'Province',
    next: 'Suivant',
    no: 'Non',
    phoneNumber: 'Numéro de téléphone',
    submit: 'Soumettre',
    timezone: 'Fuseau horaire',
    video: 'Vidéo',
    yes: 'Oui',
  },
  prompt: {
    select: 'Veuillez sélectionner une option',
  },
  provinces: {
    ontario: 'Ontario',
    alberta: 'Alberta',
    britishColumbia: 'Colombie-Britannique',
    manitoba: 'Manitoba',
    newBrunswick: 'Nouveau-Brunswick',
    newfoundlandAndLabrador: 'Terre-Neuve-et-Labrador',
    novaScotia: 'Nouvelle-Écosse',
    princeEdwardIsland: 'Île-du-Prince-Édouard',
    quebec: 'Québec',
    saskatchewan: 'Saskatchewan',
    northwestTerritories: 'Territoires du Nord-Ouest',
    yukon: 'Yukon',
    nunavut: 'Nunavut',
  },
  registration: {
    complete: {
      apr: 'Envoi de la demande de rendez-vous...',
      completing: "En cours d'enregistrement...",
      creatingUser: "Création d'un nouvel utilisateur...",
      finalizing: 'Finalisation...',
      schedulingAppt: 'Prise de rendez-vous...',
      submittingIntake: "Soumission de l'admission...",
    },
    errors: {
      duplicateHCN:
        'Il y a eu un problème avec le numéro de carte de santé fourni. Veuillez essayer un autre numéro de carte de santé. (Code #002)',
      emailTaken:
        "Il y a eu un problème avec l'adresse courriel fournie. Veuillez essayer un autre courriel.  (Code #001)",
      generic:
        "Une erreur s'est produite lors de la finalisation de votre inscription. Veuillez réessayer ou contacter le service d'assistance au 1-450-363-1410.(Code #999)",
      missingAnswer: 'Réponse manquante : {question}',
      missingField: 'Champ manquant : {field}',
      noAvailabilityForDay: 'Aucune disponibilité pour le jour sélectionné.',
      selectAppointment: 'Veuillez sélectionner une heure de rendez-vous',
      validation: {
        required: 'Champ requis',
        invalidEmail: 'Doit être une adresse e-mail valide',
        alphaOnly: 'Doit être en caractères alphabétiques',
        numbersOnly: 'Doit être en caractères numériques',
        mustAgree: 'Vous devez accepter de continuer!',
        invalidHealthcard: "Le numéro d'assurance maladie n'est pas valide.",
        invalidPhone: 'Doit être un numéro de téléphone valide',
        minLength: 'Longueur minimale {num} caractères',
        maxLength: 'Longueur max {num} caractères',
        invalidDate: 'Veuillez entrer une date valide',
      },
    },
    registerComplete: {
      buttonText: 'Terminé',
      cardTitle: "Merci d'avoir participé.",
      cardText: 'Nous vous contacterons dans les prochains jours.',
      defaultFlow: {
        cardText: 'Vous pourrez continuer dans un moment.',
      },
      trt: {
        cardTitle: 'Veuillez attendre',
      },
    },
    registerFileUpload: {
      acceptedTypes: 'Types de fichiers acceptés : {types}.',
      choose: 'Choisir le fichier',
      dragDrop: 'Glisser-déposer le fichier ici',
      fileTypeError: "{type} n'est pas un type de fichier accepté.",
      fileUploadTitle: 'Fichiers à télécharger',
      fileUploadSubtitle: 'Vos fichiers seront téléchargés lorsque vous appuyez sur "Suivant".',
      maxFiles: 'Un maximum de 5 fichiers peuvent être téléchargés.',
      new: 'Télécharger un nouveau fichier',
      or: 'Ou',
      registerComplete: 'Rendez-vous créé avec succès',
      registerThankyou: 'Merci de votre inscription.',
      subtitle: 'Cette étape est optionnel',
      uploadError: "Une erreur s'est produite lors du téléchargement du fichier",
      uploadFile: 'Télécharger vos fichier(s) ici',
      uploadingComplete: 'Telecharge terminé',
      uploadingInProgress: 'En cours de télécharger vos fichiers',
      uploadingSubtitleInProgress: 'Merci pour votre patience.',
    },
    registerNewReturning: {
      book: 'Prenez rendez-vous',
      para1:
        "Nous sommes là pour vous aider avec tout problème médical familial que vous pourriez avoir. Si vous avez une situation d'urgence médicale, veuillez appeler le 911 ou visiter votre hôpital local.",
      instructions1: 'Si vous êtes nouveau avec Sentry Health, veuillez sélectionner',
      instructions2: 'Je suis nouveau',
      instructions3: 'Si vous êtes un patient existant de Sentry, veuillez sélectionner',
      instructions4: 'Je reviens',
      instructions5: 'Ce processus est simple et rapide.',
      start: 'Commencer',
    },
    registerInform: {
      additionalComments: "D'autres commentaires",
      customReason: 'Personnalisé : {raison}',
      customReasonPrompt: 'Sélectionnez une option ou saisissez la vôtre',
      noteFee: 'Une note du médecin peut entraîner des frais.',
      optional: 'Facultative',
      prescriptionSearch: 'Recherchez vos prescriptions',
      questions: {
        annualCheckup: 'A eu un rendez-vous annuel?',
        seeSpecialist: 'Spécialiste demandé',
        hasFamilyPhysician: 'Avez-vous un médecin de famille?',
      },
      reason: 'Raison de visite',
      reasonForReferral: 'Raison de la référence',
      removePrescription: 'Supprimer ce prescription',
      title: 'Informer',
      videoCallCaveat:
        'Si vous rencontrez des problèmes techniques avec la vidéo, nous pouvons vous appeler.',
      visitType: 'Comment préférez-vous votre visite?',
    },
    registerIntroduce: {
      consent: {
        readAndAgreed: "J'ai lu et j'accepte le ",
        title: 'Consentement aux renseignements personnels sur la santé',
      },
      enterHealthCard: "Veuillez entrer votre carte d'assurance maladie",
      errors: {
        addressErrors: {
          city: 'Ville',
          country: 'Pays',
          postalCode: 'Code postale',
          province: 'Province',
          streetName: 'Rue',
          streetNumber: "Numéro d'adresse",
          text: '{champs} manquants\r\n',
          missingText: 'Adresse postale manquante ou invalide',
          tip: 'Assurez-vous de sélectionner une adresse dans le menu déroulant.',
        },
        noUser: "Impossible de trouver l'utilisateur.",
      },
      expiry: "Date d'expiration",
      gender: 'Sexe attribué à la naissance',
      healthCardExpired:
        'Il semble que votre carte Santé soit expirée. Pour éviter les delais, veuillez essayer de le renouveler avant votre rendez-vous.',
      healthCardNumber: '@:main.hcn',
      poBox: 'Boîte postale\r',
      termsOfService: 'Consentement aux renseignements personnels sur la santé',
      title: 'Introduire',
      versionCode: 'Code de version',
    },
    registerSchedule: {
      appointmentSelected: 'Rendez-vous sélectionné',
      datesAvailable: 'Dates disponible\r\n',
      pleaseSelectProvider: 'Veuillez sélectionner un praticien pour voir sa disponibilité.',
      selectProvider: 'Sélectionnez un praticien',
      title: 'Horaire',
      wrongTimezone: "Ce n'est pas votre fuseau horaire?",
    },
    tags: {
      optional: 'optionnel',
    },
    cannabis: {
      registerFileUpload: {
        pageTitle: 'Veuillez télécharger les fichiers médicaux pertinents',
      },
      registerIntroduce: {
        pageTitle: "S'il vous plaît, parlez-nous un peu de vous",
        disclaimer:
          'Sentry Health est un fournisseur indépendant de médicaments spécialisés qui accorde la priorité à la protection de votre vie privée. Sentry Health ne partage pas, ne communique pas et ne discute pas de votre évaluation ou de vos antécédents médicaux avec des entités provinciales ou fédérales.',
      },
      registerIntroduceReturning: {
        pageTitle: 'Bienvenue, veuillez entrer vos informations',
        pageSubtitle:
          "Aidez-nous à vous identifier en entrant votre numéro de carte Santé. Ceci est pour les patients Sentry qui reviennent. Si vous avez besoin d'aide, n'hésitez pas à nous envoyer un message.",
      },
      registerInform: {
        form: {
          cannabisPatient: 'Êtes-vous un patient actif du cannabis?',
          currentLP: 'Qui est votre fournisseur?',
          currentRx: 'Quelle est votre prescription courante? (grammes par jour)',
          doseIncrease: "Avez-vous besoin d'une augmentation de grammes?",
          questionOne: 'A eu un rendez-vous annuel?',
          questionTwo: 'Spécialiste demandé',
          questionThree: 'Avez-vous un médecin de famille?',
          symptomRelief: 'Votre prescription courante vous permet-elle de soulager vos symptômes?',
          thcLimit: 'Quelle est votre limite de THC?',
          educationLevel: "Quel est le niveau d'études le plus élevé que vous ayez atteint?",
          educationLevels: {
            none: "Pas d'éducation formelle",
            primary: 'Enseignement primaire',
            secondary: 'Enseignement secondaire',
            ged: "Développement de l'enseignement général",
            vocational: 'Qualification professionnelle',
            bachelor: 'Baccalauréat',
            master: 'Diplôme de maître',
            phd: 'Doctorat ou plus',
          },
        },
        pageTitle: 'Veuillez nous expliquer comment nous pouvons vous aider',
        pageSubtitle:
          "Veuillez décrire votre état de santé en détail. Si nous ne disposons pas de suffisamment d'informations, nous pouvons vous appeler pour recueillir des informations supplémentaires.",
        psych: 'Psychiatrique',
        nonPsych: 'Non-psychiatrique',
        cannabisReferral: 'Référence au cannabis',
      },
      registerNewReturning: {
        para1:
          "Nous guidons les patients dans l'obtention et l'utilisation de cannabis médical pour améliorer leur santé et leur bien-être. Notre équipe d'experts propose des plans de traitement personnalisés pour les besoins individuels.\r",
      },
      registerSchedule: {
        pageTitle: 'Veuillez sélectionner une heure de rendez-vous',
        pageSubtitle: ' ',
      },
      registerSurvey: {
        pageTitle: 'Questions de dépistage des troubles liés à la consommation de cannabis',
        pageSubtitle:
          "Avez-vous vécu l'une des situations suivantes au cours des 12 derniers mois?",
      },
    },
  },
};
