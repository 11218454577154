export const PROVINCE_NAMES = {
  ON: 'ontario',
  AB: 'alberta',
  BC: 'britishColumbia',
  MB: 'manitoba',
  NB: 'newBrunswick',
  NL: 'newfoundlandAndLabrador',
  NS: 'novaScotia',
  PEI: 'princeEdwardIsland',
  QC: 'quebec',
  SK: 'saskatchewan',
  NU: 'nunavut',
  NT: 'northwestTerritories',
  YT: 'yukon',
};

export default [
  {
    key: PROVINCE_NAMES.ON,
    displayName: 'Ontario',
    healthCardName: 'Ontario Health Card (OHIP)',
    disabled: false,
    display: true,
    format: '####-###-###',
    expiryFormat: 'day',
  },
  {
    key: PROVINCE_NAMES.AB,
    displayName: 'Alberta',
    healthCardName: 'Alberta Health Care Insurance Plan (AHCIP)',
    disabled: false,
    display: true,
    format: '#####-####',
    expiryFormat: 'day',
  },
  {
    key: PROVINCE_NAMES.BC,
    displayName: 'British Columbia',
    healthCardName: 'BC Medical Services Plan (MSP)',
    disabled: false,
    display: true,
    format: '####-###-###',
    expiryFormat: 'day',
  },
  {
    key: PROVINCE_NAMES.MB,
    displayName: 'Manitoba',
    healthCardName: 'Manitoba Health Card/Registration Certificate',
    disabled: false,
    display: false,
    format: '###-###-###',
    expiryFormat: 'day',
  },
  {
    key: PROVINCE_NAMES.NB,
    displayName: 'New Brunswick',
    healthCardName: 'New Brunswick Medicare Card',
    disabled: false,
    display: false,
    format: '###-###-###',
    expiryFormat: 'month',
  },
  {
    key: PROVINCE_NAMES.NL,
    displayName: 'Newfoundland Labrador',
    healthCardName: 'Nfld/L Medical Care Plan (MCP)',
    disabled: false,
    display: false,
    format: '###-###-###-###',
    expiryFormat: 'day',
  },
  {
    key: PROVINCE_NAMES.NS,
    displayName: 'Nova Scotia',
    healthCardName: 'Nova Scotia Health Card (MSI)',
    disabled: false,
    display: false,
    format: '####-###-###',
    expiryFormat: 'day',
  },
  {
    key: PROVINCE_NAMES.PEI,
    displayName: 'PEI',
    healthCardName: 'PEI Health Card',
    disabled: false,
    display: false,
    characters: 8,
    format: '########',
    expiryFormat: 'day',
  },
  {
    key: PROVINCE_NAMES.QC,
    displayName: 'Québec',
    healthCardName: 'Québec Health Insurance Card',
    disabled: false,
    display: false,
    characters: 12,
    format: 'AAAA #### ####',
    expiryFormat: 'month',
  },
  {
    key: PROVINCE_NAMES.SK,
    displayName: 'Saskatchewan',
    healthCardName: 'Saskatchewan Health Card',
    disabled: false,
    display: false,
    format: '###-###-###',
    expiryFormat: 'month',
  },
  {
    key: PROVINCE_NAMES.NU,
    displayName: 'Nunavut',
    healthCardName: 'Nunavut Health Card',
    disabled: false,
    display: false,
    format: '#########',
  },
  {
    key: PROVINCE_NAMES.NT,
    displayName: 'Northwest Territories',
    healthCardName: 'NWT Health Card',
    disabled: false,
    display: false,
    format: 'A#######',
    expiryFormat: 'day',
  },
  {
    key: PROVINCE_NAMES.YT,
    displayName: 'Yukon',
    healthCardName: 'Yukon Health Care Insurance Plan (YHCIP)',
    disabled: false,
    display: false,
    format: '###-###-###',
    expiryFormat: 'day',
  },
];
