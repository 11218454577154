export default {
  buttonStyles: {
    customButtonStyles: (vm) => {
      return {
        'border-radius': vm.card ? '8px' : '20px',
        background: !vm.disabled && !vm.selected && !vm.hover ? 'unset' : '#1e193b',
        color: !vm.disabled && !vm.selected && !vm.hover ? '#1e193b' : 'white',
        border:
          !vm.hover && !vm.selected && !vm.disabled && !vm.card ? '1px solid #1e193b' : 'unset',
        transition: 'background 0.3s',
        'font-weight': '600!important',
        'font-family': "'Raleway', serif!important",
        'text-transform': 'uppercase!important',
        'font-size': '1em!important',
        'line-height': '1.618',
      };
    },
  },
  theme: {
    anchor: '#1e88e5' /* blue -- info */,
    accent: {
      base: '#1e88e5' /* blue -- info */,
      darken1: '#979797' /* grey */,
    },
    info: {
      base: '#1e88e5' /* blue */,
      darken1: '#7460ee' /* indigo */,
      darken2: '#563dea' /* dark indigo */,
      darken3: '#6f42c1' /* purple */,
    },
    primary: {
      base: '#1e193b' /* dark blue */,

      lighten1: '#6c757d' /* lighter grey for text and menu hover */,
      lighten2: '#FFFFFF' /* white */,
    },
    secondary: {
      base: '#1e193b' /* dark blue */,
      darken1: '#009b74' /* slightly darker green */,
    },
    error: {
      base: '#fc4b6c' /* red */,
      lighten1: '#e83e8c' /* pink */,
    },
    success: {
      base: '#26c6da' /* sea green */,
      darken1: '#20c997' /* true green */,
    },
    warning: {
      base: '#ffb22b' /* yellow */,
      darken1: '#fd7e14' /* orange */,
      darken2: '#ef5350' /* dark orange */,
    },
    background: 'white' /* white */,
    // '#efedea'
    customGlobalStyles: {
      'font-family': "'Raleway', sans-serif!important",
    },
    customStyleSheet: 'sensimed',
  },
};
