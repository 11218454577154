/* eslint-disable import/no-cycle */
import { ApolloLink } from 'apollo-link';
import moment from 'moment';
import { logout } from './utils/session';
import { parseJwt } from './utils/validation';

import { AUTH_TOKEN, VUEX_STORE } from './constants/storage';

export const removeData = () => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem(VUEX_STORE);
};

const validateToken = (token) => {
  if (!token) return false;
  try {
    const parsed = parseJwt(token);
    const { exp } = parsed;
    const now = moment().unix();
    return exp > now;
  } catch {
    return false;
  }
};

export const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = localStorage.getItem(AUTH_TOKEN);
  const validToken = token && validateToken(token);
  const headers = {};

  if (validToken) {
    headers.authorization = `Bearer ${token}`;
    operation.setContext({
      headers,
    });
  } else if (token) {
    logout(true);
  }

  return forward(operation);
});
