<template>
  <footer class="pa-6">
    <div class="patient-portal__footer">
      <div
        class="patient-portal__footer--section"
        :class="{ 'align-center': $vuetify.breakpoint.xsOnly }"
      >
        <h2>{{ $t('patientPortal.footer.about') }}</h2>
        <a href="https://www.sentryhealth.ca">{{ $t('patientPortal.footer.sentry') }}</a>
      </div>
      <div class="patient-portal__footer--section align-center">
        <h2>{{ $t('patientPortal.footer.info') }}</h2>
        <a href="https://www.sentryhealth.ca/privacy-policy">{{
          $t('patientPortal.footer.privacyPolicy')
        }}</a>
        <a href="https://www.sentryhealth.ca/privacy-notice">{{
          $t('patientPortal.footer.privacyNotice')
        }}</a>
        <a href="https://www.sentryhealth.ca/acceptable-use-policy">{{
          $t('patientPortal.footer.acceptableUse')
        }}</a>
        <a href="https://www.sentryhealth.ca/sentry-meet-sla">{{
          $t('patientPortal.footer.meetSla')
        }}</a>
      </div>
      <div
        class="patient-portal__footer--section"
        :class="{
          'align-end': $vuetify.breakpoint.smAndUp,
          'align-center': $vuetify.breakpoint.xsOnly,
        }"
      >
        <h2>{{ $t('patientPortal.footer.contact') }}</h2>
        <a href="https://www.sentryhealth.ca/book-your-appointment">{{
          $t('patientPortal.footer.contactUs')
        }}</a>
      </div>
    </div>
  </footer>
</template>
<style lang="scss" scoped>
@import '../../styles/utility.scss';
.patient-portal__footer {
  display: grid;
  grid-template-rows: repeat(3, auto);
  row-gap: 1em;
  color: #0e2a47;
  width: fit-content;
  max-width: 90vw;
  margin: auto;
  min-width: min(500px, 90vw);
  padding: 0px $spacer * 4;
  height: auto;

  @include sm {
    grid-template-columns: repeat(3, auto);
    row-gap: 0px;
  }
  a {
    color: inherit;
    font-size: 0.7em;
  }
  h2 {
    font-size: 0.9em;
  }
  .patient-portal__footer--section {
    display: flex;
    flex-direction: column;
  }
}
</style>
<script>
export default {
  props: {
    dark: Boolean,
  },
};
</script>
