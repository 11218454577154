/* eslint-disable import/no-cycle */
import to from 'await-to-js';
import pageFlows from '../constants/pageflows';
import { themeCodes } from '../constants/themes';
import { apolloClient } from '../vue-apollo';
import tenantDefaults from '../constants/tenants';
import i18n from '../plugins/i18n';

const getDefaultState = () => {
  return {
    theme: 'default',
  };
};

const registrationInitModule = {
  state: getDefaultState(),
  getters: {},
  mutations: {},
  actions: {
    setPharmacyId({ commit }, { pharmacyId }) {
      if (!pharmacyId) {
        commit('setRegistration', { pharmacyReferralId: null });
        localStorage.removeItem('pharmacyId');
      } else {
        commit('setRegistration', { pharmacyReferralId: pharmacyId });
        localStorage.pharmacyId = pharmacyId;
      }
    },
    async getProviderId({ commit, getters }, { tenantUrl = getters.getTenant() }) {
      if (!tenantUrl) return;

      if (tenantUrl === getters.getTenant() && getters.getProviderId()) {
        commit('setRegistration', { providerId: getters.getProviderId(), tenantUrl });
        return;
      }

      const [errors, query] = await to(
        apolloClient.query({
          query: await getters.getQuery('GetProviderId'),
          variables: {
            tenantUrl,
          },
        })
      );
      const { providerId } = query.data;
      if (errors || !providerId) {
        throw new Error(i18n.t('errors.noProvider'));
      } else if (providerId) {
        localStorage.tenantUrl = tenantUrl;
        commit('setRegistration', {
          providerId,
          tenantUrl,
        });
      }
    },
    async registerInit(
      { dispatch, commit },
      {
        tenantUrl,
        flow = null,
        theme = null,
        onDemand = false,
        pharmacyId = null,
        employeeRef = null,
      }
    ) {
      commit('setLoading', true);
      try {
        await dispatch('getProviderId', { tenantUrl });
        await dispatch('setPharmacyId', { pharmacyId });
        await dispatch('setPageFlow', { tenantUrl, flow });
        dispatch('setTheme', { tenantUrl, selectedTheme: theme });
        dispatch('setOnDemand', { onDemand, flow });
        dispatch('setEmployeeReferral', { employeeRef });
        commit('setLoading', false);
      } catch (error) {
        commit('setLoading', false);
        commit('setNotification', {
          color: 'error',
          text: error,
          showing: true,
          timeout: 3000,
        });
      }
    },
    setEmployeeReferral({ commit }, { employeeRef }) {
      if (employeeRef) {
        localStorage.employeeRef = employeeRef;
        commit('setRegistration', {
          referral: { employee: employeeRef },
          employeeId: employeeRef,
          restrictShiftsByEmployee: true,
        });
      } else {
        localStorage.removeItem('employeeRef');
      }
    },
    setOnDemand({ commit }, { onDemand, flow }) {
      if (onDemand || pageFlows.global[flow]?.onDemand) {
        commit('setRegistration', {
          onDemand: true,
          appointmentRequest: true,
          appointmentTime: null,
        });
        localStorage.setItem('onDemand', onDemand);
      } else {
        commit('setRegistration', {
          onDemand: false,
          appointmentRequest: false,
          appointmentTime: null,
        });
        localStorage.removeItem('onDemand');
      }
    },
    setPageFlow({ commit }, { tenantUrl, flow }) {
      if (flow && pageFlows.global[flow]) {
        commit('set', {
          pageFlow: pageFlows.global[flow].flow,
          flowConfig: { ...pageFlows.global[flow].config },
        });
      } else if (pageFlows.tenant[tenantUrl]) {
        commit('set', {
          pageFlow: pageFlows.tenant[tenantUrl].flow,
          flowConfig: { ...pageFlows.tenant[tenantUrl].config },
        });
      } else {
        commit('set', {
          pageFlow: pageFlows.tenant.demo.flow,
          flowConfig: {},
        });
      }

      if (flow) {
        localStorage.flow = flow;
      } else {
        localStorage.flow = 'demo';
      }
    },
    async setPreferredPharmacy({ commit, getters }, { pharmacyId }) {
      if (pharmacyId) {
        const [errors, query] = await to(
          apolloClient.query({
            query: await getters.getQuery('PharmacyLookupById'),
            variables: {
              id: pharmacyId,
            },
          })
        );
        if (errors) {
          throw new Error(i18n.t('errors.getPharmacyError'));
        } else {
          const pharmacy = { ...query.data.pharmacyLookupById, id: pharmacyId };
          commit('setPatientData', { pharmacy });
        }
      } else {
        commit('setPatientData', { pharmacy: null });
      }
    },
    setTheme({ commit }, { tenantUrl, selectedTheme }) {
      if (!selectedTheme) return;
      if (themeCodes[selectedTheme]) {
        localStorage.theme = selectedTheme;
        commit('set', { theme: themeCodes[selectedTheme] });
      } else if (tenantDefaults[tenantUrl].theme) {
        commit('set', { theme: tenantDefaults[tenantUrl].theme });
      } else {
        localStorage.removeItem('theme');
        commit('set', { theme: tenantDefaults[tenantUrl].theme });
      }
    },
  },
};

export default registrationInitModule;
