export default {
  theme: {
    accent: {
      base: '#1e88e5' /* blue -- info */,
      darken1: '#979797' /* grey */,
    },
    info: {
      base: '#1e88e5' /* blue */,
      darken1: '#7460ee' /* indigo */,
      darken2: '#563dea' /* dark indigo */,
      darken3: '#6f42c1' /* purple */,
    },
    primary: {
      base: '#6c757d' /* grey */,
      lighten1: '#f5f5f5' /* light grey */,
      lighten2: '#FFFFFF' /* white */,
    },
    secondary: {
      base: '#5d2a2c', // saddle brown
      lighten1: '#FFFFFF', // white
      darken1: '#6c757d',
    },
    error: {
      base: '#fc4b6c' /* red */,
      lighten1: '#e83e8c' /* pink */,
    },
    success: {
      base: '#26c6da' /* sea green */,
      darken1: '#20c997' /* true green */,
    },
    warning: {
      base: '#ffb22b' /* yellow */,
      darken1: '#fd7e14' /* orange */,
      darken2: '#ef5350' /* dark orange */,
    },
    background: '#f0f0dc', // beige
  },
};
