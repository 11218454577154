export const HOURS_IN_DAY = 24;
export const IN_HOUR_MINUTE_STEP = 15;
export const MAX_DURATION_MINUTES = 60;
export const MILLISECONDS_IN_MINUTE = 60000;
export const MINUTE_STEP = 5;
export const MINUTES_IN_HOUR = 60;

// { value: 30, label: "30 minutes"}
const options = [];
for (let index = 1; index <= MAX_DURATION_MINUTES / MINUTE_STEP; index += 1) {
  const currentMinutes = MINUTE_STEP * index;
  options.push({
    value: currentMinutes,
    label:
      currentMinutes >= MINUTES_IN_HOUR
        ? `${Math.floor(currentMinutes / MINUTES_IN_HOUR)} hour`
        : `${currentMinutes} minutes`,
  });
}
export const DURATION_OPTIONS = options;

// { value: 1080, label: "18:00"}
const hoursOptions = [];
for (let index = 0; index <= HOURS_IN_DAY; index += 1) {
  hoursOptions.push({
    value: index * MINUTES_IN_HOUR,
    label: `${String(index).padStart(2, '0')}:00`,
  });
}
export const HOURS_OPTIONS = hoursOptions;

// { value: 1095, label: "18:15"}
const hourSteps = [];
for (
  let currentMinutes = 0;
  currentMinutes < HOURS_IN_DAY * MINUTES_IN_HOUR;
  currentMinutes += IN_HOUR_MINUTE_STEP
) {
  hourSteps.push({
    value: currentMinutes,
    label: `${String(Math.floor(currentMinutes / MINUTES_IN_HOUR)).padStart(2, '0')}:${String(
      currentMinutes % MINUTES_IN_HOUR
    ).padStart(2, '0')}`,
  });
}
export const HOUR_OPTIONS_STEPS = hourSteps;
