/* eslint-disable import/prefer-default-export */
export const LOGGED_IN_ROUTES = [
  'Appointments',
  'Booking',
  'CannabisAppointment',
  'Files',
  'History',
  'Home',
  'Invoices',
  'MedicalRecord',
  'Settings',
  'Chat',
];
