/* eslint-disable no-await-in-loop */
/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import to from 'await-to-js';
import moment from 'moment';
import { apolloClient } from '../vue-apollo';
import store from './index';

export const getSoonestAppointmentSlot = async () => {
  const providerId = store.getters['patientPortal/providerId'];
  const employeeId = store.state.registration.employeeId || localStorage.getItem('employeeRef');
  const { timezone } = store.getters;
  const now = moment().tz(timezone);
  const endIntervalTime = now.clone().add(14, 'days');
  const [errors, query] = await to(
    apolloClient.query({
      query: await store.getters.getQuery('GetAvailabilityForDay'),
      variables: {
        endIntervalTime: endIntervalTime.unix(),
        startIntervalTime: now.unix(),
        providerId,
        employeeId,
        date: now.unix(),
        durationInMinutes: 15,
        timezone,
      },
      fetchPolicy: 'no-cache',
    })
  );

  if (errors) throw new Error('Error getting available appointment slots for selected date.');
  const { availability } = query.data;

  if (!availability.length) return null;

  const soonestTimeslot = availability.sort((a, b) => a.startTime - b.startTime)[0];
  return {
    startTime: moment.unix(soonestTimeslot.startTime).utc().tz(timezone),
    employeeId: soonestTimeslot.employee.id,
  };
};
