/* eslint-disable import/extensions */
/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from '@apollo/client/core';
import { createUploadLink } from 'apollo-upload-client';
import { concat } from 'apollo-link';
import { AUTH_TOKEN } from './constants/storage';
import { authMiddleware, removeData } from './auth';
import { GATEWAY_HTTP } from './constants/env';

// Install the vue plugin
Vue.use(VueApollo);

// Http endpoint
const httpEndpoint = `${GATEWAY_HTTP}/graphql`;
// Files URL root
export const filesRoot = httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;
const httpLink = createUploadLink({
  uri: httpEndpoint,
});
const cache = new InMemoryCache();
// Create the apollo client
export const apolloClient = new ApolloClient({
  // link: ,
  link: concat(authMiddleware, httpLink),
  cache,
});

// Call this in the Vue app file
export function createProvider() {
  // Create apollo client

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      // eslint-disable-next-line no-console
      console.log(
        '%cError',
        'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;',
        error.message
      );
    },
  });

  return apolloProvider;
}

// Manually call this when user log in
export async function onLogin(token) {
  localStorage.setItem(AUTH_TOKEN, token);
  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout() {
  removeData();

  try {
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
