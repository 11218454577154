export default {
  buttonStyles: {
    // eslint-disable-next-line no-unused-vars
    customButtonStyles: (vm) => {
      return {
        'border-radius': vm.card ? '8px' : '24px',
        background:
          // eslint-disable-next-line no-nested-ternary
          !vm.disabled && !vm.selected && !vm.hover
            ? 'var(--v-primary-base)!important'
            : vm.disabled
            ? 'gray'
            : 'var(--v-primary-lighten1)!important',
        color: 'white',
        border: '0px',
      };
    },
    customButtonClasses: (vm) => {
      return {
        'secondary white--text font-weight-bold': vm.variant && !vm.disabled,
      };
    },
  },
  theme: {
    anchor: '#1e88e5' /* blue -- info */,
    accent: {
      base: '#1e88e5' /* blue -- info */,
      darken1: '#979797' /* grey */,
    },
    info: {
      base: '#1e88e5' /* blue */,
      darken1: '#7460ee' /* indigo */,
      darken2: '#563dea' /* dark indigo */,
      darken3: '#6f42c1' /* purple */,
    },
    primary: {
      base: '#00263e' /* dark blue */,

      lighten1: '#0E5A8A' /* lighter blue */,
      lighten2: '#FFFFFF' /* white */,
    },
    secondary: {
      base: '#06a7e2 ' /* light blue */,
      // base: '#6c757d' /* grey */,
      // lighten1: '#6c757d' /* lighter grey for text and menu hover */,
      lighten1: '#f5f5f5' /* light grey */,
      darken1: '#6c757d',
    },
    error: {
      base: '#fc4b6c' /* red */,
      lighten1: '#e83e8c' /* pink */,
    },
    success: {
      base: '#26c6da' /* sea green */,
      darken1: '#20c997' /* true green */,
    },
    warning: {
      base: '#ffb22b' /* yellow */,
      darken1: '#fd7e14' /* orange */,
      darken2: '#ef5350' /* dark orange */,
    },
    background: '#ffffff' /* light grey */,
    customGlobalStyles: {
      '-webkit-font-smoothing': 'antialiased',
      'font-family': "'Segoe UI', sans-serif!important",
    },
    customStyleSheet: 'aurora',
  },
};
