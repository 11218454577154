// Lazyload imports
const loadPortalView = (name) => {
  return () => import(`../views/PatientPortal/${name}.vue`);
};

export default [
  {
    component: loadPortalView('Appointments'),
    name: 'Appointments',
    path: '/appointments',
  },
  {
    component: loadPortalView('CannabisAppointment'),
    name: 'CannabisAppointment',
    path: '/book-cannabis',
  },
  {
    component: loadPortalView('Files'),
    name: 'Files',
    path: '/files',
  },
  {
    component: loadPortalView('Home'),
    name: 'Home',
    path: '/home',
  },
  {
    component: loadPortalView('Login'),
    name: 'Login',
    path: '/login/:tenantUrl?',
    meta: {
      public: true,
    },
  },
  {
    component: loadPortalView('Invoices'),
    name: 'Invoices',
    path: '/invoices',
  },
  {
    component: loadPortalView('Settings'),
    name: 'Settings',
    path: '/settings',
  },
];
