export const APPOINTMENT_TYPES = {
  primaryPhone: 'primaryPhone',
  video: 'video',
  audioOnly: 'audioOnly',
  inPerson: 'inPerson',
};

export const STATUS = {
  cancelled: 'cancel',
  completed: 'complete',
  daySheetPrinted: 'daySheetPrinted',
  held: 'hold',
  inProgress: 'inProgress',
  inQueue: 'inQueue',
  noShow: 'noShow',
  pending: 'pending',
  rescheduled: 'reschedule',
};
export const STATUS_COLORS = {
  cancel: 'error',
  complete: 'success',
  daySheetPrinted: 'warning',
  hold: 'warning',
  inProgress: 'warning',
  inQueue: 'warning',
  noShow: 'error',
  pending: 'warning',
  rescheduled: 'success',
};

export const VISIBLE_NOTE_STEP = 4;

export const UNASSIGNED = 'UNASSIGNED';
