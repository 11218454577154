/* eslint-disable no-nested-ternary */
export default {
  buttonStyles: {
    customButtonStyles: (vm) => {
      return {
        'border-radius': vm.card
          ? '8px'
          : !vm.hover && !vm.selected && !vm.disabled && !vm.card
          ? '14px'
          : '20px',
        background: vm.disabled
          ? 'transparent!important'
          : !vm.selected && !vm.hover
          ? 'unset'
          : 'linear-gradient(45deg, #39b54a 0%,#a5ce39 99%)',
        'padding-top': !vm.hover && !vm.selected && !vm.disabled && !vm.card ? '4px' : '0px',
        color: !vm.disabled && !vm.selected && !vm.hover ? '#39b54a' : '#404040',
        border: !vm.hover && !vm.selected && !vm.disabled && !vm.card ? '0px' : '0px',
        'border-color':
          !vm.hover && !vm.selected && !vm.disabled && !vm.card ? 'red' : '#139145!important',
        'border-bottom':
          // eslint-disable-next-line no-nested-ternary
          !vm.hover && !vm.selected && !vm.disabled
            ? '4px solid'
            : vm.disabled && !vm.card
            ? '4px solid'
            : '0px',
        transition: 'border-radius 0.3s, background 0.3s',
        'font-weight': '900!important',
        'font-family': "'Hanken Grotesk', sans-serif!important",
        'text-transform': 'uppercase!important',
      };
    },
  },
  theme: {
    anchor: '#1e88e5' /* blue -- info */,
    accent: {
      base: '#2b9c58' /* dark green */,
      darken1: '#979797' /* grey */,
    },
    info: {
      base: '#1e88e5' /* blue */,
      darken1: '#7460ee' /* indigo */,
      darken2: '#563dea' /* dark indigo */,
      darken3: '#6f42c1' /* purple */,
    },
    primary: {
      base: '#2b9c58' /* dark green */,

      lighten1: '#6c757d' /* lighter grey for text and menu hover */,
      lighten2: '#FFFFFF' /* white */,
    },
    secondary: {
      base: '#2b9c58' /* dark green */,
      darken1: '#009b74' /* slightly darker green */,
    },
    error: {
      base: '#fc4b6c' /* red */,
      lighten1: '#e83e8c' /* pink */,
    },
    success: {
      base: '#26c6da' /* sea green */,
      darken1: '#20c997' /* true green */,
    },
    warning: {
      base: '#ffb22b' /* yellow */,
      darken1: '#fd7e14' /* orange */,
      darken2: '#ef5350' /* dark orange */,
    },
    customGlobalStyles: {
      'font-family': "'Hanken Grotesk', sans-serif!important",
    },
    customStyleSheet: 'mendo',
  },
};
