import fetchIntercept from 'fetch-intercept';

import { apolloClient } from '../vue-apollo';
import { AUTH_TOKEN } from '../constants/storage';
import { ERROR_CODES } from '../constants/errors';
import { isValidToken } from './validation';
import { removeData } from '../auth';
import REFRESH_TOKEN from '../graphql/Mutation/RefreshToken.gql';
import router from '../router';

fetchIntercept.register({
  async response(response) {
    const token = localStorage.getItem(AUTH_TOKEN);
    const isExpired = JSON.parse(response.headers.get('isexpired'));

    if (!response.ok && response.status === ERROR_CODES.unauthorized) {
      removeData();
      router.push({ name: 'Login', query: { reset: true } });
      return response;
    }

    if (isValidToken(token) && isExpired) {
      const { data } = await apolloClient.mutate({
        mutation: REFRESH_TOKEN,
        variables: { token },
      });

      const newToken = data.refreshToken;
      if (isValidToken(newToken)) localStorage.setItem(AUTH_TOKEN, newToken);
    }
    // Modify the reponse object
    return response;
  },
});
