import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as locales from '../locales/locales';

Vue.use(VueI18n);

const i18n = new VueI18n({
  missing(locale, key) {
    /**
     * logo i18n object is used for alt text in LogoImage.vue
     * Require default text for screenreaders
     */
    const [prefix] = key.split('.');
    if (prefix === 'logo') {
      return i18n.t('logo.default');
    }
    return null;
  },
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    ...locales,
  },
});

export default i18n;
