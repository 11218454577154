import i18n from '../plugins/i18n';
import { MALE } from './gender';
import { APPOINTMENT_TYPES } from './appointment';
import { LEAD_LOCATIONS } from './locations';

// A hardcoded version of the pageflows and themes for each tenant
// @TODO An endpoint that provides this data for
// a tenantUrl & optional subdomain (for multiple flows per tenant)
export default {
  tenant: {
    demo: {
      flow: [
        'RegisterVisit',
        'RegisterSchedule',
        'RegisterIntroduce',
        'RegisterInform',
        'RegisterConfirm',
        'RegisterSignUp',
        'RegisterLogout',
        'RegisterAssessment',
        'RegisterThankyou',
      ],
    },
    wholehealth: {
      flow: [
        'RegisterVisit',
        'RegisterSchedule',
        'RegisterIntroduce',
        'RegisterInformAbc',
        'RegisterConfirm',
        'RegisterSignUp',
        'RegisterLogout',
        'RegisterAssessment',
        'RegisterThankyou',
      ],
    },
  },
  global: {
    covidcare: {
      theme: 'PORTAL_THEME',
      flow: [
        'RegisterCovidLanding',
        'RegisterIntroduce',
        'RegisterInform',
        'RegisterSchedule',
        'RegisterComplete',
        'RegisterLogout',
        'RegisterCovidLanding',
      ],
      config: {
        RegisterSchedule: {
          bufferMinutes: 2880,
        },
        RegisterInform: {
          form: {
            questionTwo: {
              variableName: 'nextAppointmentDate',
              displayText: null,
              noteLabel: 'Has date for next appointment?',
              infoText: null,
              value: null,
            },
            questionThree: {
              variableName: 'pastEffects',
              displayText: null,
              noteLabel: 'Has experienced adverse effects from a previous dose?',
              infoText: null,
              value: null,
            },
          },
          isReasonForVisitFixed: true,
          reasonForVisit: 'Covid-19 Vaccine Assessment',
        },
      },
    },
    trt: {
      theme: 'PORTAL_THEME',
      flow: [
        'RegisterIntroduce',
        'RegisterComplete',
        'RegisterFileUpload',
        'RegisterLogout',
        'RegisterIntroduce',
      ],
      config: {
        RegisterFileUpload: {
          isEndOfRegistration: true,
          // TODO: Replace ids with actual pharmacy ids
          leadLocations: [
            { id: '1', name: 'St. Catharines Pharmacy' },
            { id: '2', name: 'Niagara Falls Pharmacy' },
          ],
        },
        RegisterIntroduce: {
          isEmailOptional: true,
          showGender: false,
          showEmail: false,
          patientData: {
            gender: MALE,
          },
        },
        RegisterComplete: {
          init: (store) => {
            store.commit('setRegistration', {
              appointmentReasons: 'Low Testosterone',
            });
            store.commit('setPatientData', {
              billingAddress: {
                country: 'Canada',
                province: 'Ontario',
              },
              mailingAddress: {
                country: 'Canada',
                province: 'Ontario',
              },
            });
          },
          buttonText: i18n.t('main.continue'),
          cardText: i18n.t('registration.registerComplete.trt.cardText'),
          cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
          clickToContinue: false,
          isEndOfRegistration: false,
        },
        RegisterThankyou: {
          thankyouSubtitle1: null,
          thankyouSubtitle2: null,
        },
      },
    },
    trt2: {
      theme: 'PORTAL_THEME',
      flow: [
        // 'RegisterGateway'
        'RegisterIntroduce',
        'RegisterComplete',
        'RegisterFileUpload',
        'RegisterLogout',
        'RegisterIntroduce',
      ],
      config: {
        RegisterFileUpload: {
          isEndOfRegistration: true,
          // TODO: Replace ids with actual pharmacy ids
          leadLocations: LEAD_LOCATIONS,
        },
        RegisterIntroduce: {
          isEmailOptional: true,
          showGender: false,
          showEmail: false,
          patientData: {
            gender: MALE,
          },
        },
        RegisterComplete: {
          init: (store) => {
            store.commit('setRegistration', {
              appointmentReasons: 'Low Testosterone',
            });
            if (store.state.registration.employeeId || localStorage.getItem('employeeRef')) {
              store.commit('setRegistration', {
                appointmentTime: 'asap',
                appointmentType: APPOINTMENT_TYPES.video,
              });
            }
            store.commit('setPatientData', {
              billingAddress: {
                country: 'Canada',
                province: 'Ontario',
              },
              mailingAddress: {
                country: 'Canada',
                province: 'Ontario',
              },
            });
            store.commit('addOtherPatientNote', {
              newNoteText: 'Low Testosterone',
              noteTags: ['Primary Complaint'],
            });
          },
          buttonText: i18n.t('main.continue'),
          cardText: i18n.t('registration.registerComplete.trt.cardText'),
          cardTitle: i18n.t('registration.registerComplete.trt.cardTitle'),
          clickToContinue: false,
          isEndOfRegistration: false,
        },
        RegisterThankyou: {
          thankyouSubtitle1: null,
          thankyouSubtitle2: null,
        },
      },
    },
  },
};
