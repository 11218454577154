// Some util functions for string transforms

export function toProperCase(string) {
  let value = string;

  try {
    value = value.charAt(0).toUpperCase() + value.substr(1).toLowerCase();
  } catch (e) {
    // Ignore error.
  }

  return value;
}

export function toPhoneNumber(phone) {
  let value = phone;

  try {
    // Remove brackets.
    value = value.replace('(', '').replace(')', '');

    // Replace all seperators with a dash.
    value = value.replaceAll(' ', '-').replaceAll('.', '-');

    // Add country code if not present
    if (value.length === 12) value = `1${value[0] === '-' ? value : `-${value}`}`;
  } catch (e) {
    // Ignore error.
  }

  return value;
}
