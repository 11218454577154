/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import createLogger from 'vuex/dist/logger';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import to from 'await-to-js';
import { apolloClient } from '../vue-apollo';
import { toProperCase, toPhoneNumber } from '../utils/stringTransform';
import { MINUTES_IN_HOUR } from '../constants/time';
import { VUEX_STORE, INCLUDE_KEYS, AUTH_TOKEN } from '../constants/storage';
import PROVINCES from '../constants/provinces';
import onDemandPages from '../constants/onDemand';
import { getRegistrationErrorText } from '../utils/graphql';

import { STATUS } from '../constants/appointment';
import { REGISTRATION_ERRORS } from '../constants/errors';
import f from '../utils/fetch';

import { getSoonestAppointmentSlot } from './utils';
import i18n from '../plugins/i18n';

/**
 * module imports
 */
import registrationInit from './registrationInit';
import patientPortal from './patient-portal';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: VUEX_STORE,
  storage: window.localStorage,
  reducer: (state) => {
    const persistedState = {};

    INCLUDE_KEYS.forEach((k) => {
      persistedState[k] = state[k];
    });
    return persistedState;
  },
});

const defaultNotification = {
  color: '',
  showing: false,
  text: '',
  timeout: -1,
};
const defaultAddress = {
  city: '',
  country: 'Canada',
  postalCode: '',
  province: '',
  streetName: '',
  streetNumber: '',
  suite: '',
};

const getDefaultRegistration = function (state) {
  return {
    additionalComments: null,
    appointmentDate: null,
    appointmentDuration: null,
    appointmentPreference: null, // Virtual Queue or Appointment
    appointmentReasons: null,
    appointmentRequest: null,
    appointmentSkills: [],
    appointmentTime: null,
    appointmentType: null,
    attributes: [],
    availableEmployees: [],
    confirmationText: null,
    currentPage: null,
    employeeName: null,
    employeeId: null,
    enrollInComprehensiveCare: false,
    employeePicture: null,
    employeeIds: [],
    errors: {
      emailError: null,
      registerError: null,
      errorType: null,
    },
    insurance: null,
    intake: null,
    isReturningPatient: false,
    lang: 'en',
    location: null,
    memberId: null,
    memberAttributes: [],
    navigation: {
      header: {
        back: false,
      },
    },
    onDemand: false,
    onNextClick: null,
    patientData: {
      billingAddress: {
        ...defaultAddress,
      },
      firstName: '',
      lastName: '',
      preferredName: null,
      preferredPronoun: null,
      gender: null,
      email: null,
      pharmacy: state?.registration?.patientData?.pharmacy,
      phone: {
        primary: null,
      },
      dateOfBirth: null,
      healthCard: {
        number: null,
        versionNumber: null,
        expiry: null,
        province: null,
      },
      notes: {
        basicNotes: [],
        other: [],
      },
      mailingAddress: {
        ...defaultAddress,
      },
    },
    pharmacyReferralId: null,
    prescriptionSelections: [],
    products: [],
    providerId: null,
    providerTimeZone: null,
    province: null,
    reasonForVisit: null,
    remainingFields: [],
    referral: {
      employee: null,
    },
    restrictShiftsByEmployee: false,
    route: {
      query: {},
      params: {},
    },
    saveText: null,
    selectedService: null,
    showHeader: true,
    stepCompleted: false,
    tenantUrl: null,
  };
};

const defaultUser = {
  settings: {
    endTime: 17 * MINUTES_IN_HOUR, // 17:00
    startTime: 8 * MINUTES_IN_HOUR, // 08:00
    timezone: momentTZ.tz.guess(),
  },
  info: {},
  appointment: {},
  appointmentNotify: {
    show: false,
    interval: {},
  },
};

export default new Vuex.Store({
  state: {
    memberAttributes: [],
    pageFlow: [],
    flowConfig: {},
    theme: null,
    loading: null,
    timezones: momentTZ.tz.names(),
    notification: defaultNotification,
    user: { ...defaultUser },
    registration: getDefaultRegistration(),
    loadingValue: 0,
  },
  getters: {
    getAttribute: (state) => (attributeId) => {
      return state.memberAttributes.find((a) => a.value === attributeId);
    },
    getConfig: (state) => (componentName) => {
      return state.flowConfig[componentName];
    },
    getEnvironment: () => {
      return process.env.NODE_ENV;
    },
    getFirstFlowRoute: (state) => {
      return state.pageFlow[0];
    },
    getPharmacyId: (state) => {
      return state.registration.pharmacyReferralId;
    },
    getQuery: () => async (name) => {
      const gql = await import(`../graphql/Query/${name}.gql`);
      return gql;
    },
    getMutation: () => async (name) => {
      const gql = await import(`../graphql/Mutation/${name}.gql`);
      return gql;
    },
    getTenant: (state) => () => {
      return state.registration.tenantUrl || localStorage.tenantUrl;
    },
    getTheme: (state) => () => {
      return state.theme;
    },
    getProviderId: (state) => () => {
      return state.user.info.providerId || state.registration.providerId;
    },
    isLoggedIn() {
      return Boolean(localStorage.getItem(AUTH_TOKEN));
    },
    user(state) {
      return state.user.info;
    },
    timezone(state) {
      return state.user.settings.timezone;
    },
    currentDate(state) {
      return moment.utc().tz(state.user.settings.timezone);
    },
    currentNotes(state) {
      return state.registration.patientData.notes;
    },
    appointment(state) {
      return state.user.appointment;
    },
    appointmentNotify(state) {
      return state.user.appointmentNotify;
    },
    registration(state) {
      return state.registration;
    },
    toTimezone:
      ({ user }) =>
      (t, timezone) => {
        if (typeof t === 'number') {
          return moment.unix(t).tz(user.settings.timezone).clone();
        }
        if (typeof t === 'string') {
          return moment.tz(t, timezone || user.settings.timezone).clone();
        }
        return moment(t)
          .tz(timezone || user.settings.timezone)
          .clone();
      },
    toUTC: () => (t) => {
      if (typeof t === 'number') {
        return moment.unix(t).utc().clone();
      }
      return moment.utc(t).clone();
    },
    nextFlowRoute: (state) => (componentName) => {
      let nextIndex;
      if (componentName === 'RegisterGateway') {
        nextIndex = 0;
      } else {
        nextIndex = state.pageFlow.indexOf(componentName) + 1;
      }

      while (state.registration.onDemand && onDemandPages.includes(state.pageFlow[nextIndex])) {
        nextIndex += 1;
      }
      return state.pageFlow[nextIndex];
    },
    previousFlowRoute: (state) => (componentName) => {
      let previousIndex = state.pageFlow.indexOf(componentName) - 1;
      while (state.registration.onDemand && onDemandPages.includes(state.pageFlow[previousIndex])) {
        previousIndex -= 1;
      }

      return state.pageFlow[previousIndex];
    },
    getFlowRouteIndex: (state) => (componentName) => {
      return state.pageFlow.indexOf(componentName);
    },
  },
  mutations: {
    goBackHeader(state) {
      state.registration.navigation.header.back = true;
      setTimeout(() => {
        state.registration.navigation.header.back = false;
      }, 1);
    },
    resetRegistration(state) {
      Object.assign(state.registration, getDefaultRegistration(state));
    },
    set(state, updates) {
      Object.assign(state, updates);
    },

    setCompleted(state, boolean) {
      state.registration.stepCompleted = !!boolean;
    },
    setLoading(state, bool) {
      state.loading = bool;
    },
    setLocation(state, location) {
      const { query } = state.registration.route;
      const newLocation = query.location ? { id: query.location } : location;
      Vue.set(state.registration, 'location', newLocation);
    },
    setNextButtonText(state, text) {
      if (state.registration.currentPage?.options?.nextButtonText) {
        state.registration.currentPage.options.nextButtonText = text;
      } else {
        Vue.set(state.registration.currentPage.options, 'nextButtonText', text);
      }
    },
    setOnNextClick(state, func) {
      state.registration.onNextClick = func;
    },
    setNotification(state, notification) {
      state.notification = {
        ...defaultNotification,
        ...notification,
      };
    },

    setRemainingFields(state, fields) {
      state.registration.remainingFields = fields;
    },
    saveSettings(state, settings) {
      state.user.settings = { ...state.user.settings, ...settings };
    },
    saveUser(state, info) {
      state.user.info = { ...state.user.info, ...info };

      // Convert user information into clean strings.
      try {
        state.user.info.firstName = toProperCase(state.user.info.firstName);
        state.user.info.lastName = toProperCase(state.user.info.lastName);
        if (state.user.info?.mailingAddress) {
          state.user.info.mailingAddress.streetName = toProperCase(
            state.user.info.mailingAddress.streetName
          );
        }
        if (state.user.info.phone?.primary) {
          state.user.info.phone.primary = toPhoneNumber(state.user.info.phone.primary);
        }
      } catch (e) {
        console.error('There was an error formatting user info in the VueX Store.');
      }
    },
    saveAppointment(state, appointment) {
      state.user.appointment = { ...state.user.appointment, ...appointment };
    },
    saveAppointmentNotify(state, notify) {
      state.user.appointmentNotify = { ...state.user.appointmentNotify, ...notify };
    },
    clearAppointment(state) {
      state.user.appointment = {};
    },
    restoreSession(state) {
      state.user.info = {};
    },

    setProvince(state, provinceKey) {
      const selectedProvince = PROVINCES.find((province) => province.key === provinceKey);
      if (selectedProvince) {
        state.registration.province = selectedProvince;
      } else {
        throw new Error(`Province ${provinceKey} not found.`);
      }
    },
    setConsultation(state, data) {
      state.registration.availableConsults.special = { data };
    },
    setSpecialty(state, data) {
      state.registration.availableConsults.general = { data };
    },
    setRegistration(state, updates) {
      if (updates?.location) {
        console.warn('Cannot set location via mutation setRegistration.');
        // eslint-disable-next-line no-param-reassign
        delete updates.location;
      }

      Object.assign(state.registration, updates);
    },
    setRegistrationRoute(state, route) {
      const { query, params } = route;
      Object.assign(state.registration.route, { query, params });
    },
    appendToPatientNotes(state, data) {
      state.registration.patientData.notes.push(data);
    },
    setPatientData(state, updates) {
      Object.assign(state.registration.patientData, updates);
    },
    addDefaultPatientNotes(state, newNotes) {
      Object.assign(state.registration.patientData.notes, newNotes);
    },
    addOtherPatientNote(state, note) {
      // A new note must be an object with the following key/values:
      // newNoteText: String
      // noteTags: [String]
      if (!note.noteTags) return;

      if (
        note.noteTags.includes('Primary Complaint') &&
        state.registration.patientData.notes.other.some((otherNote) => {
          return otherNote?.noteTags?.includes('Primary Complaint');
        })
      ) {
        const primaryComplaintIndex = state.registration.patientData.notes.other.findIndex(
          (otherNote) => {
            return otherNote.noteTags.includes('Primary Complaint');
          }
        );
        state.registration.patientData.notes.other.splice(primaryComplaintIndex, 1, note);
        return;
      }

      if (
        note.noteTags.includes('Gender') &&
        state.registration.patientData.notes.other.some((otherNote) => {
          return otherNote?.noteTags?.includes('Gender');
        })
      ) {
        const genderNoteIndex = state.registration.patientData.notes.other.findIndex(
          (otherNote) => {
            return otherNote.noteTags.includes('Gender');
          }
        );
        state.registration.patientData.notes.other.splice(genderNoteIndex, 1, note);
        return;
      }
      const allOtherNotes = [...state.registration.patientData.notes.other];
      allOtherNotes.push(note);
      state.registration.patientData.notes.other = allOtherNotes;
    },
  },
  actions: {
    async addAppointmentNotes(
      { state, getters },
      { appointmentId = null, appointmentRequestId = null, memberId, userExists = false }
    ) {
      const { providerId, employeeId } = state.registration;
      const { basicNotes } = state.registration.patientData.notes;

      if (basicNotes?.length) {
        await apolloClient.mutate({
          mutation: await getters.getMutation('AddMemberNote'),
          variables: {
            memberId,
            providerId,
            employeeId,
            newNoteText: basicNotes.join('\n'),
            appointmentId,
            appointmentRequestId,
          },
        });
      }
      const { other } = state.registration.patientData.notes;
      if (other?.length) {
        other.forEach(async (note) => {
          const { newNoteText, noteTags } = note;
          // do not add note that user has accepted terms of service if they are returning
          if (userExists && noteTags.includes('Consent')) return;
          await apolloClient.mutate({
            mutation: await getters.getMutation('AddMemberNote'),
            variables: {
              memberId,
              providerId,
              employeeId,
              newNoteText,
              appointmentId,
              appointmentRequestId,
              noteTags,
            },
          });
        });
      }
    },
    async newRegistration({ commit }, { clearUserData = true }) {
      await commit('resetRegistration');
      if (!clearUserData) return;
      await commit('restoreSession');
      localStorage.clear();
    },
    async checkForExistingUser({ state, getters }) {
      const {
        healthCard: { number: healthCardNumber, province },
      } = state.registration.patientData;
      const { registration } = getters;

      const [errors, query] = await to(
        apolloClient.mutate({
          mutation: await getters.getQuery('MembersByHealthCardTwo'),
          variables: {
            providerId: registration.providerId,
            healthCardNumber,
            accountForDob: false,
            province,
          },
          fetchPolicy: 'no-cache',
        })
      );
      if (!errors) {
        const [user] =
          query.data.SearchService?.searchUsers?.membersByHealthCard2
            ?.membersByHealthCard2Results || [];
        return user || {};
      }
      return null;
    },
    async getAndSaveUser({ state, commit, getters }) {
      const { data } = await apolloClient.query({
        query: await getters.getQuery('GetCurrentUser'),
        fetchPolicy: 'no-cache',
      });
      // TODO: Account for unauthorized errors

      const { endTime, startTime, timeZone } = data.getCurrentUser.metaData;
      const other = JSON.parse(data.getCurrentUser.metaData.other);
      // Save user settings
      const settings = {};

      if (endTime) settings.endTime = endTime;
      if (startTime) settings.startTime = startTime;
      if (timeZone) settings.timezone = timeZone;

      // Save user info
      const info = { ...data.getCurrentUser };
      if (info.primaryRole === 'admin') info.isAdmin = true;

      if (typeof other === 'string') {
        info.metaData.other = JSON.parse(other);
      } else {
        info.metaData.other = other;
      }

      commit('saveSettings', settings);
      commit('saveUser', info);

      return state.user;
    },
    async completeRegistration(
      { state, commit, dispatch, getters },
      {
        memberInfo,
        currentRoute,
        isEndOfRegistration,
        checkForExistingUser = true,
        sendNotification = true,
        setToken = true,
      }
    ) {
      state.registration.errors.registerError = null;
      state.registration.errors.emailError = null;
      const { email, password } = memberInfo;

      let newUser;
      let userExists = false;
      commit('set', { loadingValue: 10 });
      await commit('setRegistration', { saveText: i18n.t('registration.complete.completing') });
      if (checkForExistingUser) {
        const { userId: memberId, token } = (await dispatch('checkForExistingUser')) || {};
        if (!token && !!memberId) {
          commit('setRegistration', {
            errors: { registerError: true, errorType: REGISTRATION_ERRORS.USER_INACTIVE },
          });
          commit('setNotification', {
            color: 'error',
            text: i18n.t('registration.errors.userInactive'),
            showing: true,
            timeout: -1,
          });

          return null;
        }
        if (memberId && token) {
          await commit('setRegistration', { memberId, isReturningPatient: true });
          if (setToken) localStorage.setItem(AUTH_TOKEN, token);
          newUser = {
            id: memberId,
          };
          userExists = true;
        }
      }
      commit('set', { loadingValue: 30 });
      if (!newUser) {
        await commit('setRegistration', { saveText: i18n.t('registration.complete.creatingUser') });
        newUser = await dispatch('createMember', { email, password });
      }
      if (newUser) {
        if (state.registration.memberAttributes?.length) {
          dispatch('setMemberAttributes');
          commit('set', { loadingValue: 40 });
        }
        if (state.registration.intake) {
          await commit('setRegistration', {
            saveText: i18n.t('registration.complete.submittingIntake'),
          });
          await dispatch('submitIntake');
        }
        let appointmentId;
        let appointmentRequestId;
        if (state.registration.appointmentTime && !state.registration.appointmentRequest) {
          await commit('setRegistration', {
            saveText: i18n.t('registration.complete.schedulingAppt'),
          });
          appointmentId = await dispatch('createAppointment', { newUser, sendNotification });
        } else {
          await commit('setRegistration', { saveText: i18n.t('registration.complete.apr') });
          appointmentRequestId = await dispatch('createAppointmentRequest');
        }
        commit('set', { loadingValue: 60 });
        if (state.registration.errors.registerError) {
          commit('setRegistration', {
            errors: { registerError: false, errorType: null },
          });

          return null;
        }
        await commit('setRegistration', { saveText: i18n.t('registration.complete.finalizing') });

        if (appointmentId) {
          await dispatch('addAppointmentNotes', {
            appointmentId,
            memberId: newUser.id,
            userExists,
          });
        } else if (appointmentRequestId) {
          await dispatch('addAppointmentNotes', {
            appointmentRequestId,
            memberId: newUser.id,
            userExists,
          });
        }
        commit('set', { loadingValue: 80 });
        if (state.registration.confirmationText) {
          await commit('setRegistration', { saveText: state.registration.confirmationText });
        } else {
          await commit('setRegistration', { saveText: null });
        }

        if (!state.registration.isReturningPatient) {
          await dispatch('getAndSaveUser');
        }
        commit('set', { loadingValue: 90 });
        if (isEndOfRegistration) {
          commit('resetRegistration');
        }

        commit('setLoading', false);
        return getters.nextFlowRoute(currentRoute);
      }

      return null;
    },
    // eslint-disable-next-line no-unused-vars
    async createAppointment(
      { state, commit, dispatch, getters },
      { newUser, sendNotification = true }
    ) {
      if (state.registration.appointmentTime === 'asap') {
        const earliestTimeSlot = await getSoonestAppointmentSlot();
        if (!earliestTimeSlot) {
          return dispatch('createAppointmentRequest');
        }
        const { startTime, employeeId } = earliestTimeSlot;

        await commit('setRegistration', {
          appointmentTime: startTime,
          appointmentDate: startTime,
          employeeId,
        });
      }
      const { registration } = getters;
      const [errors, query] = await to(
        apolloClient.mutate({
          mutation: await getters.getMutation('AddAppointment'),
          variables: {
            memberId: newUser.id,
            providerId: registration.providerId,
            startTime: registration.appointmentTime.clone().utc().unix(),
            endTime: registration.appointmentTime
              .clone()
              .add(registration.appointmentDuration, 'minutes')
              .utc()
              .unix(),
            date: registration.appointmentDate.clone().utc().unix(),
            employeeId: registration.employeeId,
            status: STATUS.daySheetPrinted,
            appointmentType: registration.appointmentType,
            hasAccessCode: true,
            locationId: registration.location?.id,
            notificationPreference: sendNotification,
          },
          fetchPolicy: 'no-cache',
        })
      );
      const newAppointment = query?.data;

      if (errors || !newAppointment) {
        commit('setNotification', {
          color: 'error',
          text: 'Error creating appointment.',
          showing: true,
          timeout: 3000,
        });
        commit('setRegistration', {
          errors: { registerError: true, errorType: REGISTRATION_ERRORS.CREATE_APPOINTMENT },
        });
        commit('setLoading', false);
        return null;
      }
      const appointmentId = newAppointment.addAppointment;
      return appointmentId;
    },
    async createAppointmentRequest({ state, commit, getters }) {
      const primaryComplaint = state.registration.appointmentReasons;
      const { employeeId, memberId, providerId, additionalComments, appointmentTime, location } =
        state.registration;
      const data = {
        memberId,
        employeeId,
        locationId: location?.id,
        notes: `${primaryComplaint}${additionalComments ? `\n${additionalComments}` : ''}`,
      };
      if (appointmentTime && typeof appointmentTime === 'object') {
        data.startTime = appointmentTime.clone().utc().unix();
      }
      const [errors, query] = await to(
        apolloClient.mutate({
          mutation: await getters.getMutation('CreateAppointmentRequest'),
          variables: {
            providerId,
            data,
          },
        })
      );
      if (errors) {
        commit('setNotification', {
          color: 'error',
          text: 'Failed to create appointment request.',
          showing: true,
          timeout: 3000,
        });
        commit('setRegistration', {
          errors: {
            registerError: true,
            errorType: REGISTRATION_ERRORS.CREATE_APPOINTMENT_REQUEST,
          },
        });
        commit('setLoading', false);
        return null;
      }
      const { id = null } = query.data.createAppointmentRequest;
      commit('setLoading', false);
      return id;
    },
    async createMember({ state, commit, getters }, { email, password = null }) {
      const {
        billingAddress,
        dateOfBirth,
        firstName,
        gender,
        healthCard,
        lastName,
        pharmacy,
        phone,
        mailingAddress,
      } = state.registration.patientData;
      const { basicNotes } = state.registration.patientData.notes;
      const { providerId } = state.registration;

      // [HOTFIX REPLACE]
      let billing;
      let mailing;
      if (!billingAddress) {
        billing = {
          country: 'Canada',
          province: 'Ontario',
        };
      } else {
        billing = { ...billingAddress };
        delete billing.formattedAddress;
      }
      if (!mailingAddress) {
        mailing = {
          country: 'Canada',
          province: 'Ontario',
        };
      } else {
        mailing = { ...mailingAddress };
        delete mailing.formattedAddress;
      }

      const [errors, query] = await to(
        apolloClient.mutate({
          mutation: await getters.getMutation('PublicAddUser'),
          variables: {
            billingAddress: billing,
            // dateOfBirth: moment(dateOfBirth, 'YYYY-MM-DD').unix(),
            dateOfBirth: moment.utc(dateOfBirth).clone().unix(),
            email,
            firstName,
            gender,
            healthCard: {
              ...healthCard,
              number: healthCard.number?.replaceAll(' ', '').replaceAll('-', ''),
              expiry: healthCard.expiry ? getters.toUTC(healthCard.expiry).unix() : null,
            },
            lastName,
            locationId: state.registration.location?.id,
            memberNotes: basicNotes.length ? basicNotes.join('\n') : null,
            notificationPreference: { email: true },
            mailingAddress: mailing,
            metaData: {
              other: JSON.stringify({
                preferredPharmacy: pharmacy,
              }),
            },
            password: password || undefined,
            // disabled for now
            sendPassword: false,
            // sendPassword: !password,
            phone,
            providerId,
            type: 'member',
          },
          fetchPolicy: 'no-cache',
        })
      );

      if (errors || !query.data.PublicUser) {
        commit('setNotification', {
          color: 'error',
          text: getRegistrationErrorText(errors),
          showing: true,
          timeout: 3000,
        });

        commit('setRegistration', {
          errors: { registerError: true, errorType: REGISTRATION_ERRORS.CREATE_USER },
        });

        commit('setLoading', false);
        return null;
      }
      const { PublicUser } = query.data;
      commit('setRegistration', { memberId: PublicUser.id });
      localStorage.setItem(AUTH_TOKEN, PublicUser.token);

      return PublicUser;
    },
    async setMemberAttributes({ state, getters, dispatch }) {
      const { providerId, memberId, memberAttributes: memberAttributeIds } = state.registration;
      try {
        await apolloClient.mutate({
          mutation: await getters.getMutation('AppendAttributes'),
          variables: {
            providerId,
            memberId,
            memberAttributeIds,
            loggingInformation: window.location.href,
          },
        });
        return true;
      } catch {
        return false;
      }
    },
    async submitIntake({ state }) {
      const { providerId, intake: body, memberId } = state.registration;

      try {
        await f.post({
          route: `providers/${providerId}/publicForms`,
          body: {
            ...body,
            memberId,
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
    async updateUser({ commit, getters }) {
      // Can't destructure user into this for some reason...
      const user = this.state.user.info;

      try {
        await apolloClient.mutate({
          mutation: await getters.getMutation('UpdateUser'),
          variables: {
            userId: user.id,
            type: user.primaryRole,
            providerId: user.providerId,
            updates: {
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              gender: user.gender,
              dateOfBirth: user.dateOfBirth,
              phone: {
                primary: user.phone.primary,
              },
              mailingAddress: {
                streetNumber: user.mailingAddress.streetNumber,
                streetName: user.mailingAddress.streetName,
                postalCode: user.mailingAddress.postalCode,
                city: user.mailingAddress.city,
                province: user.mailingAddress.province,
                country: user.mailingAddress.country,
                suite: user.mailingAddress.suite,
              },
              notificationPreference: {
                email: user.notificationPreference?.email || false,
                sms: user.notificationPreference?.sms || false,
              },
              healthCard: {
                number: user.healthCard.number,
                versionNumber: user.healthCard.versionNumber,
                expiry: user.healthCard.expiry,
              },
              metaData: {
                other: JSON.stringify(user.metaData.other),
              },
            },
          },
        });
        commit('setNotification', {
          color: 'var(--v-success-base)',
          timeout: 5000,
          showing: true,
          text: 'User info saved.',
        });
      } catch (e) {
        commit('setNotification', {
          color: 'var(--v-error-base)',
          timeout: 5000,
          showing: true,
          text: 'Could not save.',
        });
      }
    },
  },
  plugins: [
    vuexLocalStorage.plugin,
    ...(process.env.NODE_ENV === 'development' ? [createLogger()] : []),
  ],
  modules: {
    registrationInit,
    patientPortal,
  },
});
