const {
  VUE_APP_FIREBASE_API_KEY: FIREBASE_API_KEY,

  VUE_APP_FIREBASE_APP_ID: FIREBASE_APP_ID,

  VUE_APP_FIREBASE_AUTH_DOMAIN: FIREBASE_AUTH_DOMAIN,

  VUE_APP_FIREBASE_MESSAGING_ID: FIREBASE_MESSAGING_ID,

  VUE_APP_FIREBASE_PROJECT_ID: FIREBASE_PROJECT_ID,
  VUE_APP_BOOKING_SOURCE: BOOKING_SOURCE,
  VUE_APP_GATEWAY_HTTP: GATEWAY_HTTP,
  VUE_APP_PUBLIC_ASSETS_BUCKET,
  VUE_APP_PUBLIC_HASH: PUBLIC_HASH,
  VUE_APP_NODE_ENV: NODE_ENV,
  VUE_APP_FORMS_API: FORMS_API,
  VUE_APP_GOOGLE_API: GOOGLE_API_KEY,
  VUE_APP_ERROR_REPORTING_API_KEY: ERRORS_API_KEY,
  VUE_APP_ERROR_REPORTING_PROJECT_NAME: ERRORS_PROJECT_NAME,
} = process.env;

const PROD = 'prod';
const QA = 'qa';
const DEV = 'dev';

export {
  BOOKING_SOURCE,
  DEV,
  ERRORS_API_KEY,
  ERRORS_PROJECT_NAME,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_ID,
  FIREBASE_PROJECT_ID,
  FORMS_API,
  GATEWAY_HTTP,
  GOOGLE_API_KEY,
  NODE_ENV,
  PROD,
  PUBLIC_HASH,
  QA,
  VUE_APP_PUBLIC_ASSETS_BUCKET,
};
