/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-cycle */
import store from '../store';
import router from '../router';
import { onLogout } from '../vue-apollo';

export const logout = async (notify = false) => {
  const { tenantUrl } = store.getters['patientPortal/currentUser'];
  await store.commit('restoreSession');
  await onLogout();
  router.push({ name: 'Login', params: { tenantUrl } });
  if (!notify) return;
  // Set timeout ensures that this notification will take priority over any query-specific error msgs
  setTimeout(() => {
    store.commit('setNotification', {
      color: 'warning',
      timeout: -1,
      showing: true,
      text: 'Session has expired. For your security, you have automatically been logged out.',
    });
  }, 50);
};
