export const ERROR_CODES = {
  unauthorized: 401,
  notFound: 404,
};

const ERRORS = {
  [ERROR_CODES.notFound]: {
    icon: 'magnify',
    desc: 'The page you were looking was not found.',
  },
  [ERROR_CODES.unauthorized]: {
    icon: 'cancel',
    desc: "You don't have the permissions.",
  },
  auth: {
    icon: 'help',
    desc: 'Try logging out first.',
  },
  default: {
    desc: 'Something went wrong, try again.',
  },
  hash: {
    desc: 'Incorrect upload password. Try again.',
  },
};

export const REGISTRATION_ERRORS = {
  EMAIL_TAKEN: 'emailTaken',
  CREATE_USER: 'createUser',
  CREATE_APPOINTMENT_REQUEST: 'createAppointmentRequest',
  CREATE_APPOINTMENT: 'createAppointment',
  DUPLICATE_HCN: 'duplicateHCN',
  USER_INACTIVE: 'inactiveUser',
};

export default ERRORS;
