/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import registrationRoutes from './registration';
import portalRoutes from './portal';
import { AUTH_TOKEN } from '../constants/storage';

// Lazyload imports
const loadView = (name) => {
  return () => import(`../views/${name}.vue`);
};

Vue.use(VueRouter);

const routes = [
  registrationRoutes,
  ...portalRoutes,
  {
    component: loadView('Registerv2'),
    name: 'Register',
    path: '/register/:tenantUrl/:id?',
  },
  {
    component: loadView('RegisterDisabled'),
    name: 'RegisterDisabled',
    path: '/no_virtual',
  },
  {
    component: loadView('RegisterNotFound'),
    name: 'NotFound',
    path: '/notFound',
  },

  {
    component: loadView('FillSurvey'),
    name: 'FillSurvey',
    path: '/fill-survey',
  },
  // {
  //   component: loadView('Booking'),
  //   name: 'Booking',
  //   path: '/booking',
  // },

  {
    component: loadView('History'),
    name: 'History',
    path: '/history',
  },

  {
    component: loadView('Chat'),
    name: 'Chat',
    path: '/chat',
  },

  {
    component: loadView('PublicUploads'),
    name: 'PublicUploads',
    path: '/public-uploads',
    meta: {
      public: true,
    },
  },
  {
    component: loadView('TermsOfService'),
    name: 'TermsOfService',
    path: '/tos',
    meta: {
      public: true,
    },
  },
  {
    component: loadView('ViewMemberFile'),
    name: 'ViewMemberFile',
    path: '/view-file',
    meta: {
      public: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Blank URL defaults to last tenant or sentry-health tenant
  if (to.path === '/') {
    const lastTenant = localStorage.getItem('tenantUrl');
    next({ name: 'Register', params: { tenantUrl: lastTenant || 'sentry-health' } });
    return;
  }
  const isRegisterPath = (path) => {
    return path.matched.some((p) => p.name === 'Register');
  };

  const ALLOWED_ROUTES = [
    'PublicUploads',
    'ViewMemberFile',
    'Login',
    'Appointments',
    'CannabisAppointment',
    'Home',
    'Chat',
    'Files',
    'Invoices',
    'Settings',
    'FillSurvey',
    'TermsOfService',
  ];

  const SECURE_ROUTES = ['Appointments', 'Home', 'Chat'];

  const isSecureRoute = (path) => {
    return path.matched.some((p) => SECURE_ROUTES.includes(p.name));
  };

  const isAllowedRoute = (path) => {
    return path.matched.some((p) => ALLOWED_ROUTES.includes(p.name));
  };
  const isRegisterGateway = (path) => {
    return path.matched.some((p) => p.name === 'RegisterGateway');
  };

  if (isRegisterPath(to) && !isRegisterGateway(to) && !store.getters.getProviderId()) {
    const { tenantUrl } = to?.params || {};
    if (tenantUrl) {
      await store.commit('setLoading', true);
      await store.dispatch('getProviderId', { tenantUrl });
      await store.commit('setLoading', false);
    }
  }

  const isRoute = (path) => {
    return routes.some((route) => route.name === path.name);
  };

  const isLoggedIn = Boolean(localStorage.getItem(AUTH_TOKEN));

  const goLogin = (params) => {
    const tenantUrl = params?.tenantUrl || localStorage.getItem('tenantUrl') || 'sentry-health';
    next({ name: 'Login', params: { tenantUrl } });
  };

  if (!isLoggedIn && isSecureRoute(to)) {
    goLogin(to.params);
    return;
  }
  if (
    isRegisterPath(to) ||
    isAllowedRoute(to) ||
    to.name === 'NotFound' ||
    to.name === 'RegisterDisabled'
  ) {
    next();
  } else if (!isRoute(to)) {
    next({ name: 'NotFound', query: { errorCode: 404 } });
  } else {
    next({ name: 'NotFound', query: { errorCode: 302 } });
  }
});

export default router;
