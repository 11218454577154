import moment from 'moment-timezone';

const en = {
  format: {
    prettyDateLong: (ctx) => {
      const date = ctx.named('date');
      const timezone = moment.tz.guess();
      return `${moment.unix(date).tz(timezone).format('dddd MMMM Do[,] YYYY')} at ${moment
        .unix(date)
        .format('HH:mm A')}`;
    },
    fullName: (ctx) => {
      const firstName = ctx.named('firstName') || '';
      const lastName = ctx.named('lastName') || '';

      return `${lastName}${lastName && firstName ? `, ` : ''}${firstName}`.trim() || 'N/A';
    },
  },
  addressInput: {
    placeholder: 'Search for a location',
  },
  appointmentStatus: {
    cancel: 'Cancelled',
    complete: 'Completed',
    daySheetPrinted: 'Day sheet printed',
    hold: 'On hold',
    inProgress: 'In progress',
    inQueue: 'In waiting room',
    noShow: 'No show',
    pending: 'Pending',
    reschedule: 'Rescheduled',
  },
  appointments: {
    description: 'Here you will find all currently active and previous @.lower:main.appointments',
    loading: 'Loading @.lower:main.appointments',
    none: 'No @.lower:main.appointments found.',
    tooltip: {
      expired: 'This @.lower:main.appointment has expired.',
      go: 'Go to @.lower:main.appointment',
      cancelled: 'This @.lower:main.appointment has been @.lower:appointmentStatus.cancel',
      completed: 'This @.lower:main.appointment has been @.lower:appointmentStatus.complete',
      rescheduled: 'This @.lower:main.appointment has been @.lower:appointmentStatus.reschedule',
      upcoming: 'Upcoming @.lower:main.appointment',
    },
    title: '@:main.appointments',
  },
  errors: {
    getPharmacyError: 'There was a problem retrieving your preferred pharmacy.',
    noAvailabilityForDay: 'No availability for selected day.',
    noProvider: 'No provider available.',
    noRocketChat: 'Live chat did not load correctly.',
    noTimezone: 'No timezone available for provider.',
  },
  fillSurvey: {
    error: 'Could not initialize survey. Please ensure all necessary data is provided.',
    exit: 'Exit',
    submitting: 'Submitting...',
    submitted: 'Questionnaire submitted!',
  },
  input: {
    cancel: 'Cancel',
    go: 'Go',
    save: 'Save',
    edit: 'Edit',
    discard: 'Discard',
  },
  logo: {
    anova: 'Anova Fertility & Reproductive Health',
    aurora: 'Aurora Cannabis',
    cannabis: 'Sentry Health',
    ccc: 'Canadian Cannabis Clinics',
    default: 'Sentry Health',
    hybridpharm: 'Hybrid Pharm',
    mendo: 'Mendo Medical Cannabis',
    sensimed: 'Sensi Medical',
    vivo: 'Vivo Cannabis',
    'woodbridge-transfer': 'Sentry Health',
  },
  main: {
    actions: 'Actions',
    afternoon: 'Afternoon',
    appointment: 'Appointment',
    appointments: 'Appointments',
    back: 'Back',
    close: 'Close',
    continue: 'Continue',
    confirm: 'Confirm',
    date: 'Date',
    delete: 'Delete',
    disclaimer: 'Disclaimer',
    dob: 'Date of birth',
    email: 'Email address',
    employee: 'Employee',
    evening: 'Evening',
    expiry: 'Expiry',
    firstName: 'First name',
    gender: {
      male: 'Male',
      female: 'Female',
      pronouns: {
        him: 'He/Him/His',
        her: 'She/Her/Hers',
        they: 'They/Them/Theirs',
        ze: 'Ze/Hir/Hirs',
        none: 'I do not use a pronoun.',
        other: 'Other, please ask',
        all: 'I use all gender pronouns.',
      },
    },
    hcn: 'Health card number',
    hcnExpiry: 'Health card expiry',
    inPerson: 'In person',
    lastName: 'Last name',
    loadMore: 'Load more',
    location: 'Location',
    mailingAddress: 'Mailing address',
    morning: 'Morning',
    next: 'Next',
    no: 'No',
    patient: 'Patient',
    paymentEmail: 'payments@sentryhealth.ca',
    pin: 'Pin',
    phone: 'Phone',
    phoneNumber: 'Phone number',
    phoneNumberPrimary: '@:main.phoneNumber (primary)',
    phoneNumberSecondary: '@:main.phoneNumber (secondary)',
    preferredName: 'Preferred name',
    preferredPronoun: 'Preferred pronoun',
    provider: 'Provider',
    province: 'Province',
    refresh: 'Refresh',
    status: 'Status',
    send: 'Send',
    submit: 'Submit',
    supportNumber: '1-705-328-2019',
    time: 'Time',
    timezone: 'Timezone',
    yes: 'Yes',
    video: 'Video',
  },
  notFound: {
    301: 'Page Unavailable',
    302: 'Page Temporarily Unavailable',
    404: 'Page Not Found',
  },

  patientPortal: {
    appointments: {
      cancelAppointment: 'Cancel appointment',
      defaultEmployee: 'Sentry Health practitioner',
      error: {
        fetch:
          'There was an error fetching appointments. If the issue persists, please contact support at @:main.supportNumber',
      },
      isCancelled: 'Appointment cancelled.',
      join: 'Join now',
      location: 'Appointment location',
      noPast: 'No @.lower:patientPortal.appointments.past',
      noUpcoming: 'No @.lower:patientPortal.appointments.upcoming',
      past: 'Past appointments',
      primaryComplaint: 'Reason for visit',
      resendEmail: 'Resend confirmation email',
      time: 'Appointment time',
      upcoming: 'Upcoming appointments',
      type: {
        video: 'Video',
        primaryPhone: 'Phone',
        audioOnly: 'Phone',
        inPerson: 'In person',
      },
    },
    files: {
      error:
        'There was an error fetching files. If the issue persists, please contact support at @:main.supportNumber',
      helpTitle: "Can't find a file?",
      helpSubtitle: "Give us a call at {0} and we'll help you find it",
      noFiles: 'No files were found.',
      subtitle: 'Here you can view files that your provider has shared with you.',
      title: 'Shared files',
      tooltip: {
        download: 'Download file',
        view: 'View file in new tab',
      },
      upload: {
        maxFiles: 'A maximum of {max} file may be uploaded at a time.',
        maxFilesPlural: 'A maximum of {max} files may be uploaded at a time.',
        maxSize: 'The size of file "{name}" exceeds {max} mB.',
        pendingFile: 'File to upload',
        pendingFiles: 'Files to upload',
        subtitle: 'Drag and drop a file into the area below, or click to select a file manually.',
        title: 'Upload files',
        titleComplete: 'Upload completed.',
        titlePending: 'Uploading...',
        tooltip: {
          error:
            'Error uploading file. Please try again or contact @:main.supportNumber for assistance.',
          remove: 'Remove file',
          success: 'File uploaded successfully.',
        },
        unlockError:
          'File was uploaded but may not appear in your "Shared Files". Please contact us for assistance.',
        upload: 'Upload file',
        uploadMultiple: 'Upload {num} files',
      },
      uploadFiles: 'Upload files',
    },
    footer: {
      about: 'About',
      acceptableUse: 'Acceptable use policy',
      contact: 'Contact',
      contactUs: 'Contact us',
      info: 'Information',
      meetSla: 'Sentry Meet SLA',
      privacyNotice: 'Privacy notice',
      privacyPolicy: 'Privacy policy',
      sentry: 'Sentry Health',
    },
    home: {
      files: 'Files',
      upcomingAppointments: '@:patientPortal.appointments.upcoming',
      title: 'Welcome back, {name}!',
    },
    login: {
      confirmPin: 'Confirm @:main.pin',
      createPin: 'Create @:main.pin',
      forgotPin: 'Forgot your @:main.pin',
      privacyConsent: "I have read and agree to Sentry Health's",
      privacyPolicy: 'Privacy policy',
      errors: {
        invalidHcn: '@:main.hcn is invalid.',
        invalidPin: 'Invalid PIN. Please try again',
        noMember:
          'Error finding patient record. Please ensure your health card number and date of birth are correct.',
        pinLength: '@:main.pin must be {length} digits in length.',
        pinMismatch: '@:main.pin numbers do not match.',
      },
      existingPinSubtitle: 'Please enter your secure PIN.',
      infoSubtitle: 'Please enter your health card number and date of birth',
      newPinSubtitle:
        "It looks like this is your first time logging in. Please create a unique 4-digit PIN. You'll use this PIN to log into the Patient Portal.",
      resetPinInstruction: 'For assistance, please call ',
      title: 'Login',
      welcome: 'Welcome!',
      welcomeBack: 'Welcome back!',
    },
    invoices: {
      end: 'End of invoices.',
      error:
        'There was an error fetching invoices. If the issue persists, please contact support at @:main.supportNumber',
      errorProduct:
        'Error fetching invoice product names. Data on invoices may not be accurate ({error})',
      paymentButton: {
        assistance: 'If you require any assistance, please email @.lower:main.paymentEmail',
        inProgress: 'Payment in progress',
        pay: 'Pay invoice',
        payNow: 'Pay now',
        for: 'for {fileName}',
        instructions:
          'You will be redirected to a new page where you can pay the invoice. Once payment is completed, you may need to {action} to view your unlocked file.',
        refresh: 'refresh this page',
      },
      none: 'No invoices.',
      status: {
        paid: 'Paid',
        pastDue: 'Payment overdue',
        pending: 'Payment due',
      },
      title: 'Invoices',
      pendingCount: 'Pending invoices',
      unavailable:
        'Our billing services are undergoing maintenance and are currently unavailable. You can send payments via interac to payments@sentryhealth.ca, or pay by cash at your nearest Sentry Health location.',
    },
    navigation: {
      appointments: 'Appointments',
      cannabisAppointment: 'Book Cannabis Appointment',
      files: 'My documents',
      home: 'Home',
      invoices: 'Invoices',
      logout: 'Logout',
      otherServices: 'Other Services',
      settings: 'Settings',
    },
    notifications: {
      end: 'End of notifications',
      error: 'There was an error loading notifications.',
      none: 'No notifications.',
      title: 'Notifications',
      expiredHealthCard: 'Please renew your health card as soon as possible.',
      appointmentInProgress: 'You have an @.lower:main.appointment currently in progress.',
      upcomingAppointment: 'You have an @.lower:main.appointment in {time} minutes.',
      viewAppointmentsBtn: 'View @:main.appointments',
    },
    settings: {
      clinicLocation: 'Home Clinic @:main.location',
      clinicLocationAddress: 'Home Clinic Address',
      clinicNoAddress: 'No address',
      end: 'End of logs.',
      information: {
        headers: {},
      },
      input: {
        discardChanges: '@:input.discard changes',
        editInfo: 'Edit information',
        saveChanges: '@:input.save changes',
      },
      logActions: {
        'appointment-update': 'Appointment cancelled',
        'login-attempt-successful': 'Successful login',
        'login-attempt-failed': 'Failed login attempt',
        'file-create': 'File created',
        'file-update': 'File updated',
      },
      none: 'No logs were found.',
      notification: {
        error:
          'There was an error fetching logs. If the issue persists, please contact support at @:main.supportNumber',
        updateInfo: {
          success: 'User data updated successfully',
          fail: 'Error updating user data',
          validatePin: {
            title: 'Please re-enter your PIN to save your edited personal information.',
          },
        },
      },
      validation: {
        required: 'Field is required',
        invalidEmail: 'Must be valid e-mail',
        alphaOnly: 'Must be in alphabet characters',
        numbersOnly: 'Must be in numeric characters',
        mustAgree: 'You must agree to continue!',
        invalidHealthcard: 'Health insurance number is not valid',
        invalidPhone: 'Must be valid phone number',
        minLength: 'Minimum length {num} characters',
        maxLength: 'Maximum length {num} characters',
        invalidDate: 'Please input valid date',
      },
      subtitle: 'View your personal information and modify your settings.',
      tabs: {
        info: 'Patient Information',
        logs: 'Patient Logs',
      },
      title: 'Settings',
      viewMore: 'View more',
    },
    subtitle: 'Access your medical information',
    title: 'Patient Portal',
    validatePin: {
      title: 'Please re-enter your PIN to view your personal information.',
    },
  },
  prompt: {
    select: 'Please select an option',
  },
  provinces: {
    ontario: 'Ontario',
    alberta: 'Alberta',
    britishColumbia: 'British Columbia',
    manitoba: 'Manitoba',
    newBrunswick: 'New Brunswick',
    newfoundlandAndLabrador: 'Newfoundland & Labrador',
    novaScotia: 'Nova Scotia',
    princeEdwardIsland: 'PEI',
    quebec: 'Québec',
    saskatchewan: 'Saskatchewan',
    northwestTerritories: 'Northwest Territories',
    yukon: 'Yukon',
    nunavut: 'Nunavut',
    abbrev: {
      ontario: 'ON',
      alberta: 'AB',
      britishColumbia: 'BC',
      manitoba: 'MB',
      newBrunswick: 'NB',
      newfoundlandAndLabrador: 'NL',
      novaScotia: 'NS',
      princeEdwardIsland: 'PE',
      quebec: 'QC',
      saskatchewan: 'SK',
      northwestTerritories: 'NT',
      yukon: 'YT',
      nunavut: 'NU',
    },
  },
  registration: {
    cannabis: {
      registerFileUpload: {
        pageTitle: 'Please upload relevant medical file(s)',
      },
      registerIntroduce: {
        pageTitle: 'Please, tell us a bit about yourself',
        disclaimer:
          'Sentry Health is an independent provider of specialty medicines and places your privacy first. Sentry Health does not share, communicate nor discuss your medical assessment or history with any provincial or federal entities.',
      },
      registerIntroduceReturning: {
        pageTitle: 'Welcome back, please enter your details',
        pageSubtitle:
          "Help us identify you by entering your health card number. This is for returning Sentry patients. If you require help, please don't hesitate to message us.",
      },
      registerInform: {
        form: {
          cannabisPatient: 'Are you an active cannabis patient?',
          currentLP: 'Who is your current LP?',
          currentRx: 'What is your current prescription (grams/day)?',
          doseIncrease: 'Do you need an increase in grams?',
          questionOne: 'Has has annual checkup?',
          questionThree: 'Do you have a family physician?',
          questionTwo: 'Specialist Requested',
          isVeteran: 'Is veteran',
          kNumber: 'K number',
          symptomRelief: 'Are you finding symptom relief from your current prescription?',
          thcLimit: 'What is your THC % limit?',
          educationLevel: 'What is the highest level of education you have completed?',
          educationLevels: {
            none: 'No formal education',
            primary: 'Primary education',
            secondary: 'Secondary education',
            ged: 'GED',
            vocational: 'Vocational qualification',
            bachelor: "Bachelor's degree",
            master: "Master's degree",
            phd: 'Doctorate or higher',
          },
        },
        pageTitle: 'Please tell us how we can help you',
        pageSubtitle:
          'Please describe your medical condition in detail. If we do not have enough information we may call you to gather additional details.',
        psych: 'Psych',
        nonPsych: 'Non-psych',
        cannabisReferral: 'Cannabis referral',
      },
      registerNewReturning: {
        para1:
          'We guide patients through obtaining and using medical cannabis to improve their health and wellness. Our team of experts provides personalized treatment plans for individual needs.',
      },
      registerSchedule: {
        pageTitle: 'Please select an appointment time',
        pageSubtitle: ' ',
      },
      registerSurvey: {
        pageTitle: 'Cannabis Use Disorder Screening Questions',
        pageSubtitle: 'Have you experienced any of the following in the last 12 months?',
      },
    },
    complete: {
      apr: 'Submitting appointment request...',
      completing: 'Completing registration...',
      creatingUser: 'Creating new user...',
      finalizing: 'Finalizing...',
      schedulingAppt: 'Scheduling appointment...',
      submittingIntake: 'Submitting intake...',
    },
    errors: {
      createAppointment: 'Error: Could not create appointment.',
      createAppointmentRequest: 'Error: Could not create appointment request.',
      createUser: 'Error: Could not create user.',
      duplicateHCN:
        'There was an issue with the health card number provided. Please try a different health card number. (Code #002)',
      emailTaken:
        'There was an issue with the email provided. Please try a different email. (Code #001)',
      generic:
        'There was an error finalizing your registration. Please try again or contact support at 1-888-957-0320. (Code #999)',
      missingAnswer: 'Missing answer: {question}',
      missingField: 'Missing field: {field}',
      noAvailabilityForDay: 'No availability for selected day.',
      selectAppointment: 'Please select an @.lower:main.appointment time',
      validation: {
        required: 'Field is required',
        invalidEmail: 'Must be valid e-mail',
        alphaOnly: 'Must be in alphabet characters',
        numbersOnly: 'Must be in numeric characters',
        mustAgree: 'You must agree to continue!',
        invalidHealthcard: 'Health insurance number is not valid',
        invalidPhone: 'Must be valid phone number',
        minLength: 'Minimum length {num} characters',
        maxLength: 'Maximum length {num} characters',
        invalidDate: 'Please input valid date',
      },
      userInactive: 'There was an error (code #003). Please contact support at 1-888-957-0320.',
    },
    profile: {
      location: '@:main.location of practice',
      specialties: 'Area(s) of specialization',
    },
    pwa: {
      landing: {
        para1: `We guide patients through through the process of improving their health and wellness. Our team of experts provides personalized treatment plans for individual needs.`,
      },
    },
    tags: {
      optional: 'optional',
    },
    registerAssessment: {
      complete: 'Complete Assessment',
      title: 'Assessment',
    },
    registerCovid: {
      consent: 'I would like a physician to follow my vaccination',
      getStarted: 'Get Started',
      message: {
        part1:
          'Sentry Health has partnered with your local pharmacy to provide supportive care regarding your COVID questions or vaccination.',
        part2: 'After your vaccine, you may experience some side effects including',
        part3: 'injection pain soreless, dizziness, mild fever or lethargy.',
        part4:
          ' The Sentry team will be available to speak with you regarding your vaccination or medical concerns',
      },
      title: 'COVID Vaccination Care Program',
    },
    registerComplete: {
      buttonText: 'Complete',
      cardTitle: 'Thank you for participating.',
      cardText: "We'll be in contact within the next couple days.",
      defaultFlow: {
        cardTitle: 'Completing registration',
        cardText: 'You will be able to continue in just a moment.',
      },
      trt: {
        cardTitle: 'Please wait',
        cardText: 'You will be able to continue in just a moment.',
      },
    },
    registerConfirm: {
      appointmentDate: '@:main.appointment @:main.date',
      appointmentDuration: '@:main.appointment Duration',
      appointmentTime: '@:main.appointment @:main.time',
      appointmentType: 'Type of Visit',
      confirmApr: '@:main.confirm Appointment Request',
      reasons: 'Reason(s) for Visit',
      title: 'Confirm',
    },
    registerFileUpload: {
      acceptedTypes: 'Accepted file types: {types}.',
      choose: 'Choose file',
      dragDrop: 'Drag & Drop @:registration.registerFileUpload.title here',
      fileTypeError: '{type} is not an accepted file type.',
      fileUploadTitle: 'Files to Upload',
      fileUploadSubtitle: 'Your files will be uploaded when you press "Next".',
      maxFiles: 'A maximum of 5 files may be uploaded.',
      new: 'Upload New @:registration.registerFileUpload.title',
      or: 'or',
      subtitle: 'This step is optional.',
      title: 'File',
      uploadError: 'Error uploading file',
      uploadFile: 'Upload file(s) here',
      uploadingInProgress: 'Uploading files',
      uploadingComplete: 'Upload Complete',
      uploadingSubtitleInProgress: 'Thank you for your patience.',
      uploadingSubtitleComplete: 'Your files were successfully uploaded.',
      registerComplete: 'Appointment Created',
      registerThankyou: 'Thank you for registering.',
    },
    registerInform: {
      additionalComments: 'Additional comments',
      customReason: 'Custom: {reason}',
      customReasonPrompt: 'Select an option or type your own',
      enterReason: 'Please enter a reason',

      noteFee: "A doctor's note may incur a fee.",
      optional: 'Optional',
      prescriptionSearch: 'Search for your prescription',
      questions: {
        annualCheckup: 'Have you had your annual checkup?',
        docNoteRequired: "Do you need a doctor's note?",
        enrollInComprehensiveCare: 'Would you like to enroll in comprehensive care?',
        hasFamilyPhysician: 'Do you have a family physician?',
        isVeteran: 'Are you a veteran?',
        kNumber: '(Optional) Enter your veteran K Number',
        nextAppointmentDate: 'Do you have a date for your next vaccine appointment?',
        pastEffects: 'Have you experienced any adverse effects from a previous dose?',
        seeSpecialist: 'Would you like to see a specialist?',
      },
      reason: 'Reason for visit',
      reasonForReferral: 'Reason for referral',
      removePrescription: 'Remove Prescription',
      title: 'Inform',
      videoCallCaveat: 'If you experience technical issues with video, we can give you a call.',
      visitType: 'How would you like your visit?',
    },
    registerIntroduce: {
      consent: {
        readAndAgreed: 'I have read and agree to the',
        title: 'Personal Health Information Consent',
      },
      enterHealthCard: 'Please enter in your health card',
      errors: {
        addressErrors: {
          city: 'City',
          country: 'Country',
          postalCode: 'Postal code',
          province: 'Province',
          streetName: 'Street name',
          streetNumber: 'Street number',
          text: 'Missing {fields}',
          missingText: 'Missing or invalid mailing address',
          tip: 'Please make sure you select an address from the dropdown menu.',
        },
        firstNameErrors: {
          maxLength: 'First name may not exceed 100 characters.',
          minLength: 'First name must be at least 2 characters.',
        },
        lastNameErrors: {
          maxLength: 'Last name may not exceed 100 characters.',
          minLength: 'Last name must be at least 2 characters.',
        },
        noUser: 'Could not find user.',
      },
      expiry: 'Expiry date',
      gender: 'Sex assigned at birth',
      healthCardNumber: '@:main.hcn',
      healthCardExpired:
        'It appears that your health card has expired. To avoid delays, please try to renew it before your appointment.',
      moreInfo: 'More information',
      poBox: 'PO Box',
      termsOfService: 'Agreed to the terms of service',
      title: 'Introduce',
      versionCode: 'Ver. code',
    },
    registerNewReturning: {
      book: 'Book an appointment',
      para1:
        "We're here to assist you with any family medical concerns you may have. If you have a medical emergency situation, please call 911 or visit your local hospital.",
      instructions1: 'If you are new to Sentry Health please select',
      instructions2: "I'm New",
      instructions3: "If you're an existing Sentry patient, please select",
      instructions4: "I'm Returning",
      instructions5: 'This process is quick and easy.',
      start: 'Get started',
    },
    registerSchedule: {
      aprScheduled:
        'An @.lower:main.appointment will be made for {date} with {employee}. Please finish registering your account to make the request.',
      appointmentSelected: 'Appointment selected',
      clinicTime: 'Current clinic time',
      datesAvailable: 'Dates are available',
      noAvailability: 'There is no remaining availability.',
      noEmployeeAvailability: '{employee} has no remaining availability.',
      pleaseSelectProvider: 'Please select a provider to view their schedule.',
      selectDate: 'Please select a date',
      selectAnotherDate: {
        please: 'Please',
        select: 'Select another day',
        press: 'or press',
      },
      selectAPR: {
        please: 'Please press ',
        pleaseWithOtherDays: 'Please select another day, or press',
        contact: 'Contact me',
        followUp: 'and we will contact you directly to schedule an appointment.',
      },
      selectProvider: 'Select a provider',
      title: 'Schedule',
      wrongTimezone: 'Not your @.lower:main.timezone?',
    },
    registerSignup: {
      consent: {
        readAndAgreed: 'I have read and agree to the',
        title: 'Personal Health Information Consent',
      },
      disclaimer:
        'Sentry Health observes the Narcotics and Controlled Substance policy and limits the prescribing of these drugs during virtual appointments.',
      title: 'Create',
    },
    registerSurvey: {
      noData:
        'The provider has no @.lower:registration.registerSurvey.title initialized. Please continue in the flow.',
      complete: '@:registration.registerSurvey.title Complete!',
      title: 'Intake',
    },
    registerThankyou: {
      subtitle1: "We'll reach out soon to see how your visit went.",
      subtitle2: "We'd really like to know.",
      title: 'Thank You for visiting',
    },
    registerVisit: {
      title: 'Visit',
      scheduleVisit: 'Schedule Visit',
    },
  },
  viewMemberFile: {
    error: 'Could not view file. Please double check the date of birth and health card number.',
    errorGeneral:
      'Could not view file. Please contact your health practitioner for further assistance.',
    errorExpired:
      'Your secure file link has expired. Please contact your health practitioner for further assistance.',
    prompt: 'Please enter your @.lower:main.dob to view your file',
    retrievingFile: 'Retrieving your secure file...',
    tooltip: {
      invalidHcn: 'Health card number is invalid',
      noDob: 'Please enter your date of birth',
      noHcn: 'Please enter your health card number',
    },
    welcome: 'Welcome to your secure file viewer.',
  },
};

export default en;
