export default {
  buttonStyles: {
    // eslint-disable-next-line no-unused-vars
    customButtonStyles: (vm) => {
      return {
        'border-radius': vm.card ? '8px' : '14px',
        background:
          // eslint-disable-next-line no-nested-ternary
          !vm.disabled && !vm.selected && !vm.hover
            ? 'var(--v-primary-base)!important'
            : vm.disabled
            ? 'gray'
            : 'var(--v-primary-lighten1)!important',
        color: vm.disabled ? '' : '#000048!important',
        border: vm.selected && !vm.disabled ? '2px solid' : '0px',
        'border-color': '#2fb7f2  !important',
        transform: !vm.disabled && (vm.selected || vm.hover) ? 'scale(1.08)' : '',
      };
    },
    customButtonClasses: (vm) => {
      return {
        'secondary white--text font-weight-bold': vm.variant && !vm.disabled,
      };
    },
  },
  theme: {
    anchor: '#1e88e5' /* blue -- info */,
    accent: {
      base: '#f0c700' /* blue -- info */,
      darken1: '#979797' /* grey */,
    },
    info: {
      base: '#1e88e5' /* blue */,
      darken1: '#7460ee' /* indigo */,
      darken2: '#563dea' /* dark indigo */,
      darken3: '#6f42c1' /* purple */,
    },
    primary: {
      base: '#4ac1e1 ' /* light blue */,

      lighten1: '#9be5fa' /* lighter blue */,
      lighten2: '#FFFFFF' /* white */,
    },
    secondary: {
      base: '#004d6f ' /* dark blue */,
      lighten1: '#f5f5f5' /* light grey */,
      darken1: '#6c757d',
    },
    error: {
      base: '#fc4b6c' /* red */,
      lighten1: '#e83e8c' /* pink */,
    },
    success: {
      base: '#26c6da' /* sea green */,
      darken1: '#20c997' /* true green */,
    },
    warning: {
      base: '#ffb22b' /* yellow */,
      darken1: '#fd7e14' /* orange */,
      darken2: '#ef5350' /* dark orange */,
    },
    background: '#ffffff' /* light grey */,
    customGlobalStyles: {
      '-webkit-font-smoothing': 'antialiased',
      'font-family': "'Poppins', sans-serif!important",
    },
    customStyleSheet: 'ccc',
  },
};
