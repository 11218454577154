// Default pageflow and theme for each tenant
// Eventually a queriable endpoint

export default {
  demo: {
    flow: 'DEMO_FLOW',
    theme: 'PORTAL_THEME',
  },
  wholehealth: {
    flow: 'WHOLE_HEALTH_FLOW',
    theme: 'WHOLE_HEALTH_THEME',
  },
};
