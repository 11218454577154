import './utils/fetch-intercept';

import Vue from 'vue';
import Fragment from 'vue-fragment';
import VueMask from 'v-mask';

import Vuelidate from 'vuelidate';
import moment from 'moment';

import { initializeApp } from 'firebase/app';
import { createProvider } from './vue-apollo';
import App from './App.vue';
import router from './router';
import i18n from './plugins/i18n';
import store from './store';
import vuetify from './plugins/vuetify';

// Firebase
import {
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_MESSAGING_ID,
  FIREBASE_PROJECT_ID,
} from './constants/env';
import { reportComponentError } from './plugins/errors';

// Filters
import './filters/formatNumber';
import './filters/formatDate';

// Customize moment fallback msg

moment.updateLocale('en', {
  invalidDate: 'N/A',
});

Vue.config.productionTip = false;

Vue.use(Fragment.Plugin);
Vue.use(VueMask);
Vue.use(Vuelidate);

// Init firebase

const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  projectId: FIREBASE_PROJECT_ID,
  messagingSenderId: FIREBASE_MESSAGING_ID,
  appId: FIREBASE_APP_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
Vue.prototype.$firebase = firebaseApp;
Vue.prototype.$reportComponentError = reportComponentError;

const vm = new Vue({
  router,
  store,
  i18n,
  vuetify,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');

window.vm = vm;
