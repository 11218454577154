<template>
  <v-app>
    <loading></loading>
    <notification-snack></notification-snack>
    <v-main v-if="!isLoggedInSection">
      <router-view></router-view>
    </v-main>
    <patient-portal-layout v-else />

    <portal-footer :dark="true" v-if="isPatientPortalSection" />
    <token-handler v-if="isLoggedInSection" />
  </v-app>
</template>

<style lang="scss">
@import './styles/global.scss';
@import './styles/register.scss';
@import './styles/transitions.scss';
@import './styles/utility.scss';
@import './styles/background.scss';
html {
  font-family: $body-font-family;
  -ms-overflow-style: none; /* Hide scroll on IE and Edge */
  scrollbar-width: none; /* Hide scroll on Firefox */
  &::-webkit-scrollbar {
    /* Hide scroll on Chrome, Safari and Opera */
    display: none;
  }
}
* {
  text-transform: none !important;
}
#app {
  height: calc(100 * var(--vh, 1vh));
  .v-application--wrap {
    min-height: calc(100 * var(--vh, 1vh));
  }
}
.v-main {
  padding: 0px !important;
}
</style>

<script>
import { themes } from './constants/themes';
import { LOGGED_IN_ROUTES } from './constants/navigation';
import PortalFooter from './components/PatientPortal/Footer.vue';
import install from './plugins/errors';

export default {
  name: 'App',
  components: {
    Loading: () => import('./components/Loading.vue'),
    NotificationSnack: () => import('./components/NotificationSnack.vue'),
    PatientPortalLayout: () => import('./components/PatientPortal/Layout.vue'),
    PortalFooter,
    TokenHandler: () => import('./components/PatientPortal/TokenHandler.vue'),
  },
  computed: {
    isLoggedInSection() {
      return LOGGED_IN_ROUTES.includes(this.$route.name);
    },
    isPatientPortalSection() {
      return [...LOGGED_IN_ROUTES, 'Login'].includes(this.$route.name);
    },

    showHeader() {
      return !this.$store.state.registration.currentPage?.options?.hideHeader;
    },
    width() {
      return this.$vuetify.breakpoint.mdAndUp ? '56px' : '0px';
    },
  },
  methods: {
    getStyleSheet(name) {
      import(`./styles/${name}.scss`);
    },
    setVh() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
  },
  async created() {
    this.$store.watch(
      (state, getters) => {
        return getters.getTheme();
      },
      (newTheme) => {
        this.$vuetify.theme.themes.light = themes[newTheme];
        if (themes[newTheme].customStyleSheet)
          this.getStyleSheet(themes[newTheme].customStyleSheet);
      }
    );

    this.setVh();
    window.addEventListener('resize', this.setVh);
    await install();
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.setVh);
  },
};
</script>
