/* eslint-disable import/no-extraneous-dependencies */
import gql from 'graphql-tag';
import i18n from '../plugins/i18n';

export const errorLibrary = {
  EMAIL_TAKEN: 'The email address is already in use',
  DUPLICATE_HCN: 'Duplicate members found for HIN',
};

export const errorLibraryText = {
  [errorLibrary.EMAIL_TAKEN]: 'registration.errors.emailTaken',
  [errorLibrary.DUPLICATE_HCN]: 'registration.errors.duplicateHCN',
  generic: 'registration.errors.generic',
};

export function getError(errors) {
  try {
    const allErrors = { ...errors };
    const singleError = allErrors.graphQLErrors[0];

    return singleError.message.replace(/Error: /gi, '').replace(/Graphql Error: /gi, '');
  } catch {
    return null;
  }
}

export function getRegistrationErrorText(errors) {
  const error = getError(errors);
  const emailTaken = new RegExp(errorLibrary.EMAIL_TAKEN);
  if (emailTaken.test(error)) return i18n.t(errorLibraryText[errorLibrary.EMAIL_TAKEN]);
  const duplicateHCN = new RegExp(errorLibrary.DUPLICATE_HCN);
  if (duplicateHCN.test(error)) return i18n.t(errorLibraryText[errorLibrary.DUPLICATE_HCN]);
  return i18n.t('registration.errors.generic');
}

const getVariableName = ({ variable }) => {
  return variable.name.value;
};

// Determine the variable kind (list or named), type (Int, String, etc), and whether or not it is nullable
const getVariableParams = (variable, returnValue = {}) => {
  const { type } = variable;
  if (type?.kind === 'NonNullType') {
    return getVariableParams(type, { nonNull: true });
  }
  const { kind } = type;
  if (kind === 'ListType') {
    return getVariableParams(type, { ...returnValue, kind });
  }

  return { ...returnValue, kind: returnValue.kind || kind, type: type.name.value };
};

const getVariableValue = (variable) => {
  const params = getVariableParams(variable);
  let value = params.kind === 'ListType' ? `[${params.type}]` : params.type;
  if (params.nonNull) value = `${value}!`;
  return value;
};

const getVariableDefinitions = (
  variableDefinitions,
  allVariableDefinitions,
  queryName,
  separateVars
) => {
  return variableDefinitions.reduce((allDefinitions, definition) => {
    const name = getVariableName(definition);
    const value = getVariableValue(definition);
    if (
      separateVars.includes(name) ||
      allVariableDefinitions.some((def) => def.name === name && def.value !== value)
    ) {
      return [
        ...allDefinitions,
        {
          name: `${name}${queryName[0].toUpperCase()}${queryName.substring(1)}`,
          value,
          defaultValue: definition?.defaultValue?.value,
          originalName: name,
        },
      ];
    }

    return [...allDefinitions, { name, value, defaultValue: definition?.defaultValue?.value }];
  }, []);
};

const getQueryNames = (customName, query) => {
  const name = query.definitions[0].selectionSet.selections[0].name.value;

  return { name, customName };
};

const getQueryArguments = (variableDefinitions, query, queryName, separateVars) => {
  const variables = query.definitions[0].selectionSet.selections[0].arguments.map((a) => a.name);
  return [...variables].reduce((allVariables, currentVariable) => {
    const { value: name } = currentVariable;
    let value = name;
    if (separateVars.includes(value))
      value = `${value}${queryName[0].toUpperCase()}${queryName.substring(1)}`;

    if (
      variableDefinitions
        .map((v) => v.name)
        .includes(`${value}${queryName[0].toUpperCase()}${queryName.substring(1)}`)
    ) {
      value = `${value}${queryName[0].toUpperCase()}${queryName.substring(1)}`;
    }
    return [...allVariables, { name, value: `$${value}` }];
  }, []);
};

const getDirectives = (selection) => {
  if (!selection?.directives?.length) return '';
  const directive = selection.directives[0];
  return ` @${directive.name.value}(${directive.arguments[0].name.value}: $${directive.arguments[0].value.name.value})`;
};
const parseNestedFields = (selections, fragments, queryName, separateVars) => {
  if (selections.kind === 'Field' && !selections.selectionSet) {
    return `${selections.alias?.value ? `${selections.alias.value}: ` : ''}${
      selections.name.value
    }${getDirectives(selections)}`;
  }
  if (selections.selectionSet) {
    if (selections.kind === 'FragmentDefinition')
      return selections.selectionSet.selections.map((s) =>
        parseNestedFields(s, fragments, queryName, separateVars)
      );

    if (selections.kind === 'InlineFragment') {
      return [
        `... on ${selections.typeCondition.name.value} { `,
        selections.selectionSet.selections.map((s) =>
          parseNestedFields(s, fragments, queryName, separateVars)
        ),
        `}`,
      ];
    }

    if (selections.arguments.length) {
      const args = [];
      selections.arguments
        .map((arg) => arg.name.value)
        .forEach((arg) => {
          if (separateVars.includes(arg)) {
            args.push(`${arg}: $${arg}${queryName[0].toUpperCase()}${queryName.substring(1)}`);
          } else {
            args.push(`${arg}: $${arg}`);
          }
        });
      return [
        `${selections.name.value}(${args.join(', ')}) { `,
        selections.selectionSet.selections.map((s) =>
          parseNestedFields(s, fragments, queryName, separateVars)
        ),
        `}`,
      ];
    }
    return [
      `${selections.name.value} ${getDirectives(selections)} { `,
      selections.selectionSet.selections.map((s) =>
        parseNestedFields(s, fragments, queryName, separateVars)
      ),
      `}`,
    ];
  }
  if (selections.kind === 'FragmentSpread') {
    const { value: fragmentName } = selections.name;
    const fragment = [...fragments].find((f) => f.name.value === fragmentName);
    return parseNestedFields(fragment, fragments, queryName, separateVars);
  }
  return null;
};

const getQueryFields = (query, queryName, separateVars) => {
  let fragments = [];
  if (query.definitions.length > 1) {
    fragments = [...query.definitions].splice(1);
  }

  return query.definitions[0].selectionSet.selections[0].selectionSet.selections
    .map((fields) => {
      const field = parseNestedFields(fields, fragments, queryName, separateVars);

      return field;
    })
    .flat(100)
    .join(`\n`);
};

/**
 * @param queries Object containing graphql schema (key: query) and the name of that query (key: name)
 * @param name String defining the name for the set of queries
 * @param separateVars Array of variable names that should have different values for each query
 * */

export const constructQuery = (queries, name, separateVars = []) => {
  // eslint-disable-next-line no-param-reassign
  if (!Array.isArray(queries)) queries = [queries];

  const queryData = queries.reduce(
    (allVars, { name: customQueryName = null, query: currentQuery }) => {
      const { variableDefinitions } = currentQuery.definitions[0];
      const { name: queryName, customName } = getQueryNames(customQueryName, currentQuery);

      const variableDefinitionsForQuery = getVariableDefinitions(
        variableDefinitions,
        allVars.variableDefinitions,
        customName || queryName,
        separateVars
      );
      const argumentsForQuery = getQueryArguments(
        variableDefinitionsForQuery,
        currentQuery,
        customName || queryName,
        separateVars
      )
        .map(({ name: argName, value }) => `${argName}: ${value}`)
        .join(', ');
      const queryFields = getQueryFields(currentQuery, customName || queryName, separateVars);
      const formattedQuery = `
        ${customName ? `${customName}: ` : ''}${queryName}(${argumentsForQuery}) {
          ${queryFields}
        }
      `;

      return {
        variableDefinitions: [...allVars.variableDefinitions, ...variableDefinitionsForQuery],
        queries: [...allVars.queries, formattedQuery],
      };
    },
    { variableDefinitions: [], queries: [] }
  );
  const q = `query ${name}(${queryData.variableDefinitions
    .reduce((allVariables, currentVariable) => {
      if (allVariables.some((v) => v.name === currentVariable.name)) {
        return [...allVariables];
      }
      return [
        ...allVariables,
        {
          name: currentVariable.name,
          value: currentVariable.value,
          defaultValue: currentVariable?.defaultValue,
        },
      ];
    }, [])
    .map((variable) => {
      let defaultValue = '';
      if (typeof variable.defaultValue === 'boolean') {
        defaultValue = ` = ${variable.defaultValue}`;
      }
      return `$${variable.name}: ${variable.value}${defaultValue}`;
    })
    .join(', ')}) {
    ${queryData.queries.join('\n')}
  }`;
  return gql`
    ${q}
  `;
};
