import Vue from 'vue';

import store from '../store';

// Reference: https://momentjs.com/docs/#/displaying/format/
Vue.filter('formatDate', (value, format, isUTC = false) => {
  const toMoment = isUTC ? store.getters.toUTC(value) : store.getters.toTimezone(value);

  return toMoment.format(format);
});
