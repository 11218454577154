import to from 'await-to-js';

import { AUTH_TOKEN } from '../constants/storage';
import { FORMS_API } from '../constants/env';

const getOptions = ({ body, ...options }) => {
  const initialOptions = {
    headers: {
      'Content-Type': 'application/json',
    },
    ...options,
  };

  if (body) {
    initialOptions.body = JSON.stringify(body);
  }

  const authToken = localStorage.getItem(AUTH_TOKEN);

  if (authToken) {
    initialOptions.headers.Authorization = `Bearer ${authToken}`;
  }

  return initialOptions;
};

const doFetch = async (route, options = {}) => {
  const [error, response] = await to(fetch(`${FORMS_API}/${route}`, getOptions(options)));

  if (error || !response.ok) {
    throw error || response;
  }

  const data = await response.json();
  return data;
};

const doPost = async ({ route, body, ...options }) => {
  const data = await doFetch(route, {
    method: 'post',
    body,
    ...options,
  });

  return data;
};

const doPut = async ({ route, body, ...options }) => {
  const data = await doFetch(route, {
    method: 'put',
    body,
    ...options,
  });

  return data;
};

const doDelete = async ({ route, ...options }) => {
  const data = await doFetch(route, {
    method: 'delete',
    ...options,
  });

  return data;
};

const customFetch = doFetch;
customFetch.put = doPut;
customFetch.post = doPost;
customFetch.delete = doDelete;

export default customFetch;
