/* eslint-disable import/no-cycle */
import GET_ALL_APPOINTMENTS from '../graphql/Query/GetAllAppointments.gql';
import UPDATE_USER from '../graphql/Mutation/UpdateUser.gql';
import { apolloClient } from '../vue-apollo';
import { STATUS } from '../constants/appointment';
import { MINUTES_IN_HOUR } from '../constants/time';
import { toProperCase } from '../utils/stringTransform';
import i18n from '../plugins/i18n';

const GRACE_PERIOD_MINUTES = 60;

const defaultUser = {
  settings: {
    endTime: 17 * MINUTES_IN_HOUR, // 17:00
    startTime: 8 * MINUTES_IN_HOUR, // 08:00
    timezone: 'America/Toronto',
  },
  info: {},
  appointment: {},
  appointmentNotify: {
    show: false,
    interval: {},
  },
};

export default {
  namespaced: true,
  state: {
    user: { ...defaultUser },
    pastAppointments: [],
    upcomingAppointments: [],
    billingDisabled: false,
  },
  getters: {
    appointments: (state) => {
      return [...state.pastAppointments, ...state.upcomingAppointments];
    },
    currentUser: (state) => {
      return state.user.info;
    },
    fullName: (state) => {
      const { firstName, lastName } = state.user.info;
      return `${firstName} ${lastName}`;
    },
    isBillingDisabled: (state) => {
      return state.billingDisabled;
    },
    pastAppointments: (state) => {
      return state.pastAppointments;
    },
    providerId: (state) => {
      return state.user.info.providerId;
    },
    upcomingAppointments: (state) => {
      return state.upcomingAppointments;
    },
    upcomingAppointmentCount:
      (state) =>
      ({ filterFunc = () => true }) => {
        const upcomingAppts = state.upcomingAppointments ?? [];
        return upcomingAppts.filter(filterFunc)?.length;
      },
  },
  mutations: {
    saveSettings(state, settings) {
      state.user.settings = { ...state.user.settings, ...settings };
    },
    saveUser(state, info) {
      state.user.info = { ...state.user.info, ...info };

      // Convert user information into clean strings.
      try {
        state.user.info.firstName = toProperCase(state.user.info.firstName);
        state.user.info.lastName = toProperCase(state.user.info.lastName);
        if (state.user.info?.mailingAddress) {
          state.user.info.mailingAddress.streetName = toProperCase(
            state.user.info.mailingAddress.streetName
          );
        }
      } catch (e) {
        console.error('There was an error formatting user info in the VueX Store.');
      }
    },
    setPastAppointments: (state, appointmentsArray) => {
      state.pastAppointments = appointmentsArray
        .filter(({ status }) => status !== STATUS.cancelled)
        .sort((a, b) => b.startTime - a.startTime);
    },
    setUpcomingAppointments: (state, appointmentsArray) => {
      state.upcomingAppointments = appointmentsArray
        .filter(({ status }) => status !== STATUS.cancelled)
        .sort((a, b) => b.startTime - a.startTime);
    },
  },
  actions: {
    updateUserInfo: async ({ state, commit }, editedData) => {
      try {
        commit('saveUser', editedData);
        await apolloClient.mutate({
          mutation: UPDATE_USER,
          variables: {
            providerId: state.user.info.providerId,
            type: state.user.info.primaryRole,
            updates: editedData.updates,
            userId: state.user.info.id,
          },
        });
        commit(
          'setNotification',
          {
            color: 'success',
            text: i18n.t('patientPortal.settings.notification.updateInfo.success'),
            timeout: 5000,
            showing: true,
          },
          { root: true }
        );
      } catch (error) {
        console.error('Error updating user data:', error);
        commit(
          'setNotification',
          {
            color: 'error',
            text: i18n.t('patientPortal.settings.notification.updateInfo.fail'),
            timeout: 5000,
            showing: true,
          },
          { root: true }
        );
      }
    },
    cancelAppointment: async ({ state, commit, rootGetters }, appointmentId) => {
      const index = state.upcomingAppointments.findIndex(({ id }) => id === appointmentId);

      if (index === -1) return;

      const { providerId } = state.user.info;

      try {
        await apolloClient.mutate({
          mutation: await rootGetters.getMutation('CancelAppointment'),
          variables: {
            providerId,
            id: appointmentId,
          },
        });
        state.upcomingAppointments.splice(index, 1);
      } catch {
        commit(
          'setNotification',
          {
            color: 'var(--v-error-base)',
            showing: true,
            text: 'Error cancelling appointment',
            timeout: 5000,
          },
          { root: true }
        );
      }
    },
    fetchPastAppointments: async ({ state, commit, rootGetters }) => {
      const { providerId, id, primaryRole: role } = state.user.info;
      try {
        const data = await apolloClient.query({
          query: GET_ALL_APPOINTMENTS,
          variables: {
            providerId,
            userFilter: {
              id,
              role,
            },
            startTimeInterval: {
              startOfInterval: rootGetters.toTimezone().subtract(1, 'years').unix(),
              endOfInterval: rootGetters
                .toTimezone()
                .subtract(GRACE_PERIOD_MINUTES, 'minutes')
                .unix(),
            },
          },
          fetchPolicy: 'no-cache',
        });
        commit('setPastAppointments', data.data.getAllAppointmentsForProvider || []);
      } catch (e) {
        console.log('error fetching past appointments: ', e);
      }
    },
    fetchUpcomingAppointments: async ({ state, commit, rootGetters }) => {
      const { providerId, id, primaryRole: role } = state.user.info;
      try {
        const data = await apolloClient.query({
          query: GET_ALL_APPOINTMENTS,
          variables: {
            providerId,
            userFilter: {
              id,
              role,
            },
            startTimeInterval: {
              startOfInterval: rootGetters
                .toTimezone()
                .subtract(GRACE_PERIOD_MINUTES, 'minutes')
                .unix(),
              endOfInterval: rootGetters.toTimezone().add(1, 'years').unix(),
            },
          },
          fetchPolicy: 'no-cache',
        });
        commit('setUpcomingAppointments', data.data.getAllAppointmentsForProvider || []);
      } catch (e) {
        console.log('error fetching past appointments: ', e);
      }
    },
  },
};
